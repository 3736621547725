import $api from "../http";
import {AxiosResponse} from 'axios';
import {OwnerResponse} from '../models/response/OwnerResponse';

export default class OwnerService {
    static async saveOwner(
        email: string,
        user: string,
        organizationalStructureLegalEntity: string,
        beneficialOwner: string,
        partControlOwner: boolean,
        fullCOntrolOwner: boolean,
        ownerSurname: string,
        ownerName: string,
        ownerPart: string,
        haveIncome: string,
        ownerPartLine: string,
        benStatus: string,
        benConfirmDoc: string,
        uploadBenConfirm: any,
        reprFio: string,
        repName: string,
        reprPatronimic: string,
        dateDateBirthday: string,
        reprEmail: string,
        reprPhone: string,
        reprBirthdayPlace: string,
        reprSerial: string,
        reprPasportNumber: string,
        pasportDate: string,
        reprCodeDepartment: string,
        reprIssuedBy: string,
        uploadPasportDoc: any,
        reprInn: string,
        reprRegRegion: string,
        reprRegCity: string,
        reprRegStreet: string,
        reprRegHouse: string,
        reprRegCorpus: string,
        reprRegApp: string,
        reprPostalCode: string,
        uploadRegistrationDoc: any,
        isLiveAddSameAsReg: string,
        isPostAddSameAsReg: string,
        addrLiveRegion: string,
        addrLiveCity: string,
        addrLiveStreet: string,
        addrLiveHouse: string,
        addrLiveCorpus: string,
        addrLiveApp: string,
        addrLivePostalCode: string,
        postLiveRegion: string,
        postLiveCity: string,
        postLiveStreet: string,
        postLiveHouse: string,
        postLiveCorpus: string,
        postLiveApp: string,
        postLivePostalCode: string,
        benPartLine: string
        ): Promise<AxiosResponse<OwnerResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('organizationalStructureLegalEntity', organizationalStructureLegalEntity);
        formData.append('beneficialOwner', beneficialOwner);
        formData.append('partControlOwner', partControlOwner.toString());
        formData.append('fullCOntrolOwner', fullCOntrolOwner.toString());
        formData.append('ownerSurname', ownerSurname);
        formData.append('ownerName', ownerName);
        formData.append('ownerPart', ownerPart);
        formData.append('haveIncome', haveIncome);
        formData.append('ownerPartLine', ownerPartLine);
        formData.append('benStatus', benStatus);
        formData.append('benConfirmDoc', benConfirmDoc);

        for (let index = 0; index < uploadBenConfirm.length; index++) {
            const photo = uploadBenConfirm[index];
            formData.append('uploadBenConfirm', photo);
        }

        formData.append('reprFio', reprFio);
        formData.append('repName', repName);
        formData.append('reprPatronimic', reprPatronimic);
        formData.append('dateDateBirthday', dateDateBirthday);
        formData.append('reprEmail', reprEmail);
        formData.append('reprPhone', reprPhone);
        formData.append('reprBirthdayPlace', reprBirthdayPlace);
        formData.append('reprSerial', reprSerial);
        formData.append('reprPasportNumber', reprPasportNumber);
        formData.append('pasportDate', pasportDate);
        formData.append('reprCodeDepartment', reprCodeDepartment);
        formData.append('reprIssuedBy', reprIssuedBy);

        for (let index = 0; index < uploadPasportDoc.length; index++) {
            const photo = uploadPasportDoc[index];
            formData.append('uploadPasportDocBen', photo);
        }

        formData.append('reprInn', reprInn);
        formData.append('reprRegCity', reprRegCity);
        formData.append('reprRegRegion', reprRegRegion);
        formData.append('reprRegStreet', reprRegStreet);
        formData.append('reprRegHouse', reprRegHouse);
        formData.append('reprRegCorpus', reprRegCorpus);
        formData.append('reprRegApp', reprRegApp);
        formData.append('reprPostalCode', reprPostalCode);

        for (let index = 0; index < uploadRegistrationDoc.length; index++) {
            const photo = uploadRegistrationDoc[index];
            formData.append('uploadRegistrationDocBen', photo);
        }

        formData.append('isLiveAddSameAsReg', isLiveAddSameAsReg);
        formData.append('isPostAddSameAsReg', isPostAddSameAsReg);
        formData.append('addrLiveRegion', addrLiveRegion);
        formData.append('addrLiveCity', addrLiveCity);
        formData.append('addrLiveStreet', addrLiveStreet);
        formData.append('addrLiveHouse', addrLiveHouse);
        formData.append('addrLiveCorpus', addrLiveCorpus);
        formData.append('addrLiveApp', addrLiveApp);
        formData.append('addrLivePostalCode', addrLivePostalCode);
        formData.append('postLiveRegion', postLiveRegion);
        formData.append('postLiveCity', postLiveCity);
        formData.append('postLiveStreet', postLiveStreet);
        formData.append('postLiveHouse', postLiveHouse);
        formData.append('postLiveCorpus', postLiveCorpus);
        formData.append('postLiveApp', postLiveApp);
        formData.append('postLivePostalCode', postLivePostalCode);
        formData.append('benPartLine', benPartLine);
        
        return $api.post<OwnerResponse>('/ownerForm', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }
}