import $api from "../http";
import {AxiosResponse} from 'axios';
import {CompanyResponse} from '../models/response/CompanyResponse';

export default class CompanyService {
    static async saveCompany(
        email: string,
        user: string,
        organizationalLegalFor: string,
        shortCompanyName: string,
        companyPhone: string,
        companyEmail: string,
        companyWebSite: string,
        ogrnName: string,
        innName: string,
        uploadOgrn: File,
        uploadInn: File,
        kppName: string,
        okpoName: string,
        okatoName: string,
        okvedName: string,
        regDate: string,
        nameRegAuthority: string,
        gosRegRegion: string,
        gosRegCity: string,
        gosRegStreet: string,
        gosRegHouse: string,
        gosRegCorpus: string,
        gosRegApp: string,
        gosPostalCode: string,
        isLegalAddSameAsGos: string,
        isPostAddSameAsGos: string,
        legalRegRegion: string,
        legalRegCity: string,
        postRegRegion: string,
        postRegCity: string,
        legalRegStreet: string,
        legalRegHouse: string,
        legalRegCorpus: string,
        legalRegApp: string,
        legalPostalCode: string,
        postRegStreet: string,
        postRegHouse: string,
        postRegCorpus: string,
        postRegApp: string,
        postPostalCode: string,
        fullCompanyName: string,
        fullCompanyNameEng: string,
        shortCompanyNamEng: string,
        abbreviation: string,
        kioName: string,
        regCountry: string,
        codeForeignerStructure: string,
        gosRegNumber: string,
        numberRecordAcredetetion: string,
        regNumberWithoutLegal: string,
        isPlaceLegalTheSame: string,
        placeOfTheLegal: string,
        okvedPartLine: string
        ): Promise<AxiosResponse<CompanyResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('organizationalLegalFor', organizationalLegalFor);
        formData.append('shortCompanyName', shortCompanyName);
        formData.append('companyPhone', companyPhone);
        formData.append('companyEmail', companyEmail);
        formData.append('companyWebSite', companyWebSite);
        formData.append('ogrnName', ogrnName);
        formData.append('innName', innName);
        formData.append('uploadOgrn', uploadOgrn);
        formData.append('uploadInn', uploadInn);
        formData.append('kppName', kppName);
        formData.append('okpoName', okpoName);
        formData.append('okatoName', okatoName);
        formData.append('okvedName', okvedName);
        formData.append('regDate', regDate);
        formData.append('nameRegAuthority', nameRegAuthority);
        formData.append('gosRegRegion', gosRegRegion);
        formData.append('gosRegCity', gosRegCity);
        formData.append('gosRegStreet', gosRegStreet);
        formData.append('gosRegHouse', gosRegHouse);
        formData.append('gosRegCorpus', gosRegCorpus);
        formData.append('gosRegApp', gosRegApp);
        formData.append('gosPostalCode', gosPostalCode);
        formData.append('isLegalAddSameAsGos', isLegalAddSameAsGos);
        formData.append('isPostAddSameAsGos', isPostAddSameAsGos);
        formData.append('legalRegRegion', legalRegRegion);
        formData.append('legalRegCity', legalRegCity);
        formData.append('postRegRegion', postRegRegion);
        formData.append('postRegCity', postRegCity);
        formData.append('legalRegStreet', legalRegStreet);
        formData.append('legalRegHouse', legalRegHouse);
        formData.append('legalRegCorpus', legalRegCorpus);
        formData.append('legalRegApp', legalRegApp);
        formData.append('legalPostalCode', legalPostalCode);
        formData.append('postRegStreet', postRegStreet);
        formData.append('postRegHouse', postRegHouse);
        formData.append('postRegCorpus', postRegCorpus);
        formData.append('postRegApp', postRegApp);
        formData.append('postPostalCode', postPostalCode);
        formData.append('fullCompanyName', fullCompanyName);
        formData.append('fullCompanyNameEng', fullCompanyNameEng);
        formData.append('shortCompanyNamEng', shortCompanyNamEng);
        formData.append('abbreviation', abbreviation);
        formData.append('kioName', kioName);
        formData.append('regCountry', regCountry);
        formData.append('codeForeignerStructure', codeForeignerStructure);
        formData.append('gosRegNumber', gosRegNumber);
        formData.append('numberRecordAcredetetion', numberRecordAcredetetion);
        formData.append('regNumberWithoutLegal', regNumberWithoutLegal);
        formData.append('isPlaceLegalTheSame', isPlaceLegalTheSame);
        formData.append('placeOfTheLegal', placeOfTheLegal);
        formData.append('okvedPartLine', okvedPartLine);

        return $api.post<CompanyResponse>('/legalForm', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }
}