import { useContext, useRef, useState } from "react";
import { Alert, Button, Card, CardBody, CardTitle, Col, Form, FormLabel, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import {Context} from "../../index";

import edit from "../../img/icons/editIcon.svg";
import done from "../../img/icons/doneIcon.svg";
import download from "../../img/icons/downloadIcon.svg";
import editWhite from "../../img/icons/Edit-white.svg";
import info from "../../img/icons/infoDocument.svg";
import success from "../../img/icons/achiveSuccess.svg";

import AccountMenu from "./parts/AccountMenu";
import URL from "../../config/default.json"
import selectFile from "../../img/icons/fileUpload.svg";
import envelope from "../../img/icons/Envelope.svg";

const AccountNewCustomer = () => {
    const {store} = useContext(Context);
    const userRole = store.user.status;
    let statusLableText:string = 'Черновик';
    let statusLableClass:string = 'continue';
    let statusLableAlert:string = 'dark';
    let statusLableAlertText:string = 'Продолжите заполнение всех анкет для получения полного доступа к платформе';
    if (store.bank.isFormCompleated && store.bank.isFormValidated && store.bank.isFormValid) {
        statusLableText = 'Согласовано';
        statusLableClass = 'done';
        statusLableAlert = 'success';
        statusLableAlertText = 'Заполнненая Вами форма прошла верификацию'
    } else if (store.bank.isFormCompleated && store.bank.isFormValidated && !store.bank.isFormValid) {
        statusLableText = 'НЕОБХОДИМА ДОРАБОТКА';
        statusLableClass = 'faild';
        statusLableAlert = 'danger';
        statusLableAlertText = 'Внесите указанные изменения в анкету и отправьте на повторную проверку'
    } else if (store.bank.isFormCompleated) {
        statusLableText = 'На проверке';
        statusLableClass = 'checking';
        statusLableAlert = 'warning';
        statusLableAlertText = 'Заполнненая Вами форма находится на проверке, оповещение о результате будет отображатся в личном кабинете'
    }


    let statusReprLableText:string = 'Черновик';
    let statusReprLableClass:string = 'continue';
    let statusReprLableAlert:string = 'dark';
    let statusReprLableAlertText:string = 'Продолжите заполнение всех анкет для получения полного доступа к платформе';
    if (store.confirmInfo.isFormConfirmCompleated && store.confirmInfo.isFormConfirmValidated && store.confirmInfo.isFormConfirmValid) {
        statusReprLableText = 'Согласовано';
        statusReprLableClass = 'done';
        statusReprLableAlert = 'success';
        statusReprLableAlertText = 'Заполнненая Вами форма прошла верификацию'
    } else if (store.confirmInfo.isFormConfirmCompleated && store.confirmInfo.isFormConfirmValidated && !store.confirmInfo.isFormConfirmValid) {
        statusReprLableText = 'НЕОБХОДИМА ДОРАБОТКА';
        statusReprLableClass = 'faild';
        statusReprLableAlert = 'danger';
        statusReprLableAlertText = 'Внесите указанные изменения в анкету и отправьте на повторную проверку'
    } else if (store.confirmInfo.isFormConfirmCompleated && store.bank && store.bank.isFormCompleated === undefined) {
        statusReprLableText = 'Сохранено';
        statusReprLableClass = 'continue';
        statusReprLableAlert = 'dark';
        statusReprLableAlertText = 'Заполненная форма сохранена. Пожалуйста, заполните форму анкеты ЮЛ'
    } else if (store.confirmInfo.isFormConfirmCompleated) {
        statusReprLableText = 'На проверке';
        statusReprLableClass = 'checking';
        statusReprLableAlert = 'warning';
        statusReprLableAlertText = 'Заполнненая Вами форма находится на проверке, оповещение о результате будет отображатся в личном кабинете'
    }

    const [validated, set_Validated] = useState(false);
    const fileLimit = 1000000000;
    const [filename1, setFilename1] = useState<string>('Загрузить анкету пользователя');
    const filePicker1 = useRef<HTMLInputElement>(null);
    const [errorFileSize1, setError1] = useState<boolean>(false);
    const uploadHandle1 = (e: any) => {
        if (e.target.files && e.target.files[0].size > fileLimit) {
            setError1(true);
            
            if (filePicker1.current) {
                filePicker1.current.value = "";
            }
        } else {
            setFilename1(e.target.files[0].name);
            setError1(false);
        }
    }

    const handlePick1 = () => {
        if (filePicker1.current) {
            filePicker1.current.click();
        }
    }

    const [filename2, setFilename2] = useState<any>(['Загрузить иные документы']);
    const [filenameList, setFilenameNew] = useState<any>([]);
    const filePicker2 = useRef<HTMLInputElement>(null);
    const [errorFileSize2, setError2] = useState<boolean>(false);
    const [errorFileSizeConfirmDoc, setErrorConfirmDoc] = useState<string>('');

    const [allFiles, setAllFiles] = useState(new Map());
    const uploadHandle2 = (e: any) => {
        let photos = e.target.files;
        var photoName: any[] = [];
        var errOneOfFile:string = '';
        if (photos.length > fileLimit) {
            setError2(true);
            if (filePicker1.current) {
                filePicker1.current.value = "";
            }
        } else {
            setError2(false);
            Array.from(photos).forEach((photo: any) => {
                if (photo.size > fileLimit) {
                    errOneOfFile = photo.name;
                } else {
                    photoName.push(photo.name);
                    setFilenameNew(photoName);
                }
            });
            setErrorConfirmDoc(errOneOfFile);
            setFilename2('Список загруженных документов')
        }

        setAllFiles((prevFiles) => {
            const updatedFiles = new Map(prevFiles);
            for (let file of photos) {
              updatedFiles.set(file.name, file); // Use file name as the key to ensure uniqueness
            }
            return updatedFiles;
        });
    }

    const handlePick2 = () => {
        if (filePicker2.current) {
            filePicker2.current.click();
        }
    }

    const submitFn = (e: any) => {
        const form = e.currentTarget.closest('form');
        if (form.checkValidity() === true) {
            e.stopPropagation();
            const upload1 = form.elements.upload1.files[0];
            const upload2 = Array.from(allFiles.values());
            store.saveDocuments(store.user.email, store.user.id, upload1, upload2);
            setShow(true);
            setFilename2('Загрузить иные документы');
            setAllFiles(new Map());
            setFilename1('Загрузить анкету пользователя');
            set_Validated(false);
        } else {
            set_Validated(true);
        }
        
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    
    return ( <>
        <div className="position-relative">
            <AccountMenu/>
            {store.confirmInfo.isFormConfirmCompleated && store.confirmInfo.isFormConfirmValidated && store.confirmInfo.isFormConfirmValid &&
            store.bank.isFormCompleated && store.bank.isFormValidated && store.bank.isFormValid ? (<>
                <div className="achive-success">
                    <div>Поздравляем, все функции платформы доступны!</div>
                    <img src={success} alt="Поздравляем, все функции платформы доступны!"/>
                </div>
            </>) : (<></>)}
                <CardTitle>Для завершения процесса регистрации и получения полного доступа к функционалу Платформы, загрузите документы:</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        <h4>Добавьте документы:</h4>
                        <Form noValidate validated={validated}>
                            <Form.Group as={Col} className="mb-3 mt-3" controlId="validation1">
                                <FormLabel>Анкета пользователя</FormLabel>
                                <Form.Control 
                                    type="file"
                                    className="hidden"
                                    onChange={uploadHandle1}
                                    accept=".zip, .rar, .xml, .tiff, .fns_1, .fns, .doc, .docx, .pdf, .jpg, .jpeg, .png, .heic"
                                    name="upload1"
                                    ref={filePicker1}
                                />
                                
                                <div className="filePicker d-flex align-items-center">
                                    <div className="me-4 fileIcon" onClick={handlePick1}><img src={selectFile} alt="Select file"/></div>
                                    <div className="fileLable">{filename1}
                                        <div className="small-txt">Формат файла должен соответствовать [.zip, .rar, .xml, .tiff, .fns_1, .fns, .doc, .docx, .pdf, .jpg, .jpeg, .png, .heic]</div>
                                    </div>
                                    {errorFileSize1  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    Файл обязателен к загрузке
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3" controlId="validation2">
                                <FormLabel>Иные документы</FormLabel>
                                <Form.Control 
                                    type="file"
                                    className="hidden"
                                    onChange={uploadHandle2}
                                    accept=".zip, .rar, .xml, .tiff, .fns_1, .fns, .doc, .docx, .pdf, .jpg, .jpeg, .png, .heic"
                                    required
                                    multiple
                                    name="upload2"
                                    ref={filePicker2}
                                />
                                
                                <div className="filePicker d-flex align-items-center">
                                    <div className="me-4 fileIcon" onClick={handlePick2}><img src={selectFile} alt="Select file"/></div>
                                    <div className="fileLable">{filename2}
                                        <div className="small-txt">Формат файла должен соответствовать [.zip, .rar, .xml, .tiff, .fns_1, .fns, .doc, .docx, .pdf, .jpg, .jpeg, .png, .heic]</div>
                                    </div>
                                    {errorFileSizeConfirmDoc !== '' ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла {errorFileSizeConfirmDoc} больше лимита в {fileLimit / URL.FILE_LIMIT_PHOTO} Mb</Alert> : ''}
                                    {errorFileSize2  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    Файл обязателен к загрузке
                                </Form.Control.Feedback>
                                <ul className="mt-3">
                                    {Array.from(allFiles.values()).map((file) => (
                                        <li key={file.name}>{file.name}</li>
                                    ))}
                                </ul>
                            </Form.Group>
                            <Button className='mt-5' variant="secondary" onClick={submitFn}>Отправить Документы</Button>
                        </Form>
                    </CardBody>
                </Card>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    <h4>Данные отправлены на обработку</h4>
                    <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleClose}>Закрыть</button>
                    </Modal.Body>
                    <Modal.Footer>
                    <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                    <div className='btn-outline-wrap-green mt-3 mb-4'>
                    <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            {userRole === 'myself' ? <>
                <Card className={`mb-3 ${statusReprLableClass}`}>
                    <CardBody>
                    Анкета Физического лица
                    <br/><br/><br/><br/>
                    <Link className="legal-entity-border" to="/myself-entity"><img src={edit} alt="Анкета представителя Юридического лица"/></Link>
                    </CardBody>
                </Card>
            </> : <></>}
            
            {userRole === 'company' || userRole === 'foreigner' ? <>
                <Card className={`mb-3 ${statusReprLableClass}`}>
                    <CardBody>
                        {store.generelInfo.email ? (<>
                            <Row>
                                <Col>
                                    <div className={`formStatusLable mb-4 ${statusReprLableClass}`}>
                                        <div className="d-flex gap-2"><embed src={editWhite} /><span>{statusReprLableText}</span></div>
                                    </div>
                                    
                                    Анкета представителя Юридического лица
                                    <br/><br/>
                                    <Alert variant={statusReprLableAlert} className="mb-5">
                                        {statusReprLableAlertText}
                                    </Alert>
                                    <Link className={store.confirmInfo && store.confirmInfo.isFormConfirmCompleated && !store.confirmInfo.isFormConfirmValidated ? 'd-none': ''} to="/representative-entity">
                                        {store.confirmInfo && store.confirmInfo.isFormConfirmCompleated && store.confirmInfo.isFormConfirmValidated && store.confirmInfo.isFormConfirmValid ? (<>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <img src={done}  className="me-2" alt="Анкета представителя Юридического лица"/>
                                                    <img src={download} alt="Анкета представителя Юридического лица"/>
                                                </div>
                                                <div>
                                                    <img src={info} alt="Анкета представителя Юридического лица"/>
                                                </div>
                                            </div>
                                            </>) : (<>
                                            <img src={edit} alt="Анкета представителя Юридического лица"/>
                                        </>)}
                                    </Link>
                                </Col>
                            </Row>
                        </>) : (<>
                            Анкета представителя Юридического лица
                            <br/><br/><br/><br/>
                            <Link className="legal-entity-border" to="/representative-entity"><img src={edit} alt="Анкета представителя Юридического лица"/></Link>
                        </>)}
                        
                    </CardBody>
                </Card>
            </> : <></>}
            {userRole === 'company' ? <>
                <Card className={statusLableClass}>
                    <CardBody>
                        {store.company.email && store.confirmInfo.isFormConfirmCompleated ? (<>
                            <Row>
                                <Col>
                                    <div className={`formStatusLable mb-4 ${statusLableClass}`}>
                                        <div className="d-flex gap-2"><embed src={editWhite} /><span>{statusLableText}</span></div>
                                    </div>
                                    
                                        Анкета Юридического лица
                                        <br/><br/>
                                        <Alert variant={statusLableAlert} className="mb-5">
                                            {statusLableAlertText}
                                        </Alert>
                                    <Link className={store.bank && store.bank.isFormCompleated && !store.bank.isFormValidated ? 'd-none': ''} to="/legal-entity">
                                        {store.bank && store.bank.isFormCompleated && store.bank.isFormValidated && store.bank.isFormValid ? (<>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <img src={done}  className="me-2" alt="Анкета представителя Юридического лица"/>
                                                    <img src={download} alt="Анкета представителя Юридического лица"/>
                                                </div>
                                                <div>
                                                    <img src={info} alt="Анкета представителя Юридического лица"/>
                                                </div>
                                            </div>
                                            </>) : (<>
                                            <img src={edit} alt="Анкета представителя Юридического лица"/>
                                        </>)}
                                    </Link>
                                </Col>
                            </Row>
                        </>) : (<>
                            Анкета Юридического лица
                            <br/><br/><br/><br/>
                            {store.confirmInfo.isFormConfirmCompleated ? (<><Link className="legal-entity-border" to="/legal-entity"><img src={edit} alt="Анкета Юридического лица"/></Link></>) : 
                            (<>
                            <Alert variant="warning" className="mb-5">Для доступа к анкета Юридического лица заполните, пожалуйста, анкету представителя Юридического лица</Alert>
                            </>)}
                            
                        </>)}
                        
                    </CardBody>
                </Card>
            </> : <></>}
            {userRole === 'foreigner' ? <>
                <Card className={`mb-3 ${statusReprLableClass}`}>
                    <CardBody>
                    Анкета иностранного Юридического лица
                    <br/><br/><br/><br/>
                    <Link className="legal-entity-border" to="/legal-entity"><img src={edit} alt="Анкета иностранного Юридического лица"/></Link>
                    </CardBody>
                </Card>
            </> : <></>}
        </div>
    </> );
}
 
export default AccountNewCustomer;