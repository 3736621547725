import $api from "../http";
import {AxiosResponse} from 'axios';
import {LicenseResponse} from '../models/response/LicenseResponse';

export default class LicenseService {
    static async saveLicense(
        email: string,
        user: string,
        typeActivity: string,
        numberAct: string,
        dateAct: string,
        officeAct: string,
        dateEnd: string,
        uploadCopyScan: File,
        isBankruptcy: string,
        isDoneBankruptcy: string,
        isLiquidation: string,
        isFailureObligation: string,
        isDebt: string,
        financialInfo: string,
        businessReputation: string,
        uploadFinanceDoc: File,
        uploadFinSituation: File,
        uploadFinReputation: File,
        amountRegistred:string,
        amountPaid:string,
        isOwnerForeignerCompany:string,
        nameOfOwnerForeigner:string,
        formOfOwnerForeigner:string,
        isProtector:string,
        nameOfProtector:string,
        protectorOrgForm:string,
        protectorCountProperties:string,
        protectorFIOOwner:string,
        protectorAddress:string

        ): Promise<AxiosResponse<LicenseResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('typeActivity', typeActivity);
        formData.append('numberAct', numberAct);
        formData.append('dateAct', dateAct);
        formData.append('officeAct', officeAct);
        formData.append('dateEnd', dateEnd);
        formData.append('uploadCopyScan', uploadCopyScan);
        formData.append('isBankruptcy', isBankruptcy);
        formData.append('isDoneBankruptcy', isDoneBankruptcy);
        formData.append('isLiquidation', isLiquidation);
        formData.append('isFailureObligation', isFailureObligation);
        formData.append('isDebt', isDebt);
        formData.append('financialInfo', financialInfo);
        formData.append('businessReputation', businessReputation);
        formData.append('uploadFinanceDoc', uploadFinanceDoc);
        formData.append('uploadFinSituation', uploadFinSituation);
        formData.append('uploadFinReputation', uploadFinReputation);
        formData.append('amountRegistred', amountRegistred);
        formData.append('amountPaid', amountPaid);
        formData.append('isOwnerForeignerCompany', isOwnerForeignerCompany);
        formData.append('nameOfOwnerForeigner', nameOfOwnerForeigner);
        formData.append('formOfOwnerForeigner', formOfOwnerForeigner);
        formData.append('isProtector', isProtector);
        formData.append('nameOfProtector', nameOfProtector);
        formData.append('protectorOrgForm', protectorOrgForm);
        formData.append('protectorCountProperties', protectorCountProperties);
        formData.append('protectorFIOOwner', protectorFIOOwner);
        formData.append('protectorAddress', protectorAddress);

        return $api.post<LicenseResponse>('/licenseForm', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }
}