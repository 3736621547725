import { FC, useContext, useState, useRef } from "react";
import {Alert, Card, CardBody, CardText, Col, Form, FormLabel, Row } from "react-bootstrap";

import {Context} from "../../../index";
import selectFile from "../../../img/icons/fileUpload.svg";
import { IGenerelInfo } from "../../../models/IGenerelInfo";

const RepresentativeForm1: FC = () => {
    const {store} = useContext(Context);
    const validated = false;
    const fileLimit = 10000000;

    const filePickerLicensePasportDoc = useRef<HTMLInputElement>(null);
    const [errorFileSizePasportDoc, setErrorPasportDoc] = useState<boolean>(false);
    const [filenamePasportDoc, setFilenamePasportDoc] = useState<string>('Загрузить скан-копию паспорта');
    const uploadPasportDocDBelemnt = store.generelInfo.uploadPasportDocPath;

    const filePickerLicenseRegistrationDoc = useRef<HTMLInputElement>(null);
    const [errorFileSizeRegistrationDoc, setErrorRegistrationDoc] = useState<boolean>(false);
    const [filenameRegistrationDoc, setFilenameRegistrationDoc] = useState<string>('Загрузить скан-копию прописки');
    const uploadRegistrationDocDBelemnt = store.generelInfo.uploadRegistrationDocPath;

    const [radioLegalStatus, setRadioLegalStatus] = useState<string>(store.generelInfo.isLiveAddSameAsReg)
    const [radioPostStatus, setRadioPostStatus] = useState<string>(store.generelInfo.isPostAddSameAsReg)

    const [form_Data, set_Form_Data] = useState<IGenerelInfo>({
        email: store.generelInfo.email,
        reprFio: store.generelInfo.reprFio,
        repName: store.generelInfo.repName,
        reprPatronimic: store.generelInfo.reprPatronimic,
        dateDateBirthday: store.generelInfo.dateDateBirthday,
        reprPhone: store.generelInfo.reprPhone,
        reprEmail: store.generelInfo.reprEmail,
        reprInn: store.generelInfo.reprInn,
        reprSNILS: store.generelInfo.reprSNILS,
        reprBirthdayPlace: store.generelInfo.reprBirthdayPlace,
        reprNationality: store.generelInfo.reprNationality,
        reprPasport: store.generelInfo.reprPasport,
        reprSerial: store.generelInfo.reprSerial,
        reprPasportNumber: store.generelInfo.reprPasportNumber,
        pasportDate: store.generelInfo.pasportDate,
        reprCodeDepartment: store.generelInfo.reprCodeDepartment,
        reprIssuedBy: store.generelInfo.reprIssuedBy,
        uploadPasportDoc: store.generelInfo.uploadPasportDoc,
        uploadPasportDocPath: uploadPasportDocDBelemnt,
        reprRegRegion: store.generelInfo.reprRegRegion,
        reprRegCity: store.generelInfo.reprRegCity,
        reprRegStreet: store.generelInfo.reprRegStreet,
        reprRegHouse: store.generelInfo.reprRegHouse,
        reprRegCorpus: store.generelInfo.reprRegCorpus,
        reprRegApp: store.generelInfo.reprRegApp,
        reprPostalCode: store.generelInfo.reprPostalCode,
        uploadRegistrationDoc: store.generelInfo.uploadRegistrationDoc,
        uploadRegistrationDocPath: uploadRegistrationDocDBelemnt,
        isLiveAddSameAsReg: radioLegalStatus,
        isPostAddSameAsReg: radioPostStatus,
        addrLiveRegion: store.generelInfo.addrLiveRegion,
        addrLiveCity: store.generelInfo.addrLiveCity,
        addrLiveStreet: store.generelInfo.addrLiveStreet,
        addrLiveHouse: store.generelInfo.addrLiveHouse,
        addrLiveCorpus: store.generelInfo.addrLiveCorpus,
        addrLiveApp: store.generelInfo.addrLiveApp,
        addrLivePostalCode: store.generelInfo.addrLivePostalCode,
        postLiveRegion: store.generelInfo.postLiveRegion,
        postLiveCity: store.generelInfo.postLiveCity,
        postLiveStreet: store.generelInfo.postLiveStreet,
        postLiveHouse: store.generelInfo.postLiveHouse,
        postLiveCorpus: store.generelInfo.postLiveCorpus,
        postLiveApp: store.generelInfo.postLiveApp,
        postLivePostalCode: store.generelInfo.postLivePostalCode
    });

    const fileNameCopyScaDB = uploadPasportDocDBelemnt ? uploadPasportDocDBelemnt.split('-f-n-').pop() : '';
    const requiredPasportDoc:boolean = uploadPasportDocDBelemnt ? false : true;

    const fileNameregistrationDocDB = uploadRegistrationDocDBelemnt ? uploadRegistrationDocDBelemnt.split('-f-n-').pop() : '';
    const requiredRegistrationDoc:boolean = uploadRegistrationDocDBelemnt ? false : true;

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    const uploadHandleChnagePasportDoc = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorPasportDoc(true);
            
            if (filePickerLicensePasportDoc.current) {
                filePickerLicensePasportDoc.current.value = "";
            }
        } else {
            setFilenamePasportDoc(e.target.files[0].name);
            setErrorPasportDoc(false);
        }
    }

    const handlePickPasportDoc = () => {
        if (filePickerLicensePasportDoc.current) {
            filePickerLicensePasportDoc.current.click();
        }
    }

    const uploadHandleChnageRegistrationDoc = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorRegistrationDoc(true);
            
            if (filePickerLicenseRegistrationDoc.current) {
                filePickerLicenseRegistrationDoc.current.value = "";
            }
        } else {
            setFilenameRegistrationDoc(e.target.files[0].name);
            setErrorRegistrationDoc(false);
        }
    }

    const handlePickRegistrationDoc = () => {
        if (filePickerLicenseRegistrationDoc.current) {
            filePickerLicenseRegistrationDoc.current.click();
        }
    }

    const handleRadioLegalChange = (score: any, inputName: string) => {
        setRadioLegalStatus(score);
        set_Form_Data({
            ...form_Data,
            [inputName]: score,
        });
        if (score === 'true') {
            let addrLiveRegion = form_Data.reprRegRegion;
            let addrLiveCity = form_Data.reprRegCity;
            let addrLiveStreet = form_Data.reprRegStreet;
            let addrLiveHouse = form_Data.reprRegHouse;
            let addrLiveCorpus = form_Data.reprRegCorpus;
            let addrLiveApp = form_Data.reprRegApp;
            let addrLivePostalCode = form_Data.reprPostalCode;
        
            set_Form_Data({
                ...form_Data,
                'addrLiveRegion': addrLiveRegion,
                'addrLiveCity': addrLiveCity,
                'addrLiveStreet': addrLiveStreet,
                'addrLiveHouse': addrLiveHouse,
                'addrLiveCorpus': addrLiveCorpus,
                'addrLiveApp': addrLiveApp,
                'addrLivePostalCode': addrLivePostalCode,
            });
        } else {
            let addrLiveRegion = form_Data.addrLiveRegion ? form_Data.addrLiveRegion : '';
            let addrLiveCity = form_Data.addrLiveCity ? form_Data.addrLiveCity : '';
            let addrLiveStreet = form_Data.addrLiveStreet ? form_Data.addrLiveStreet : '';
            let addrLiveHouse = form_Data.addrLiveHouse ? form_Data.addrLiveHouse : '';
            let addrLiveCorpus = form_Data.addrLiveCorpus ? form_Data.addrLiveCorpus : '';
            let addrLiveApp = form_Data.addrLiveApp ? form_Data.addrLiveApp : '';
            let addrLivePostalCode = form_Data.addrLivePostalCode ? form_Data.addrLivePostalCode : '' ;
            set_Form_Data({
                ...form_Data,
                'addrLiveRegion': addrLiveRegion,
                'addrLiveCity': addrLiveCity,
                'addrLiveStreet': addrLiveStreet,
                'addrLiveHouse': addrLiveHouse,
                'addrLiveCorpus': addrLiveCorpus,
                'addrLiveApp': addrLiveApp,
                'addrLivePostalCode': addrLivePostalCode,
            });
        }
    };

    const handleRadioPostChange = (score: any, inputName: string) => {
        setRadioPostStatus(score);
        set_Form_Data({
            ...form_Data,
            [inputName]: score,
        });

        if (score === 'true') {
            let postLiveRegion = form_Data.reprRegRegion;
            let postLiveCity = form_Data.reprRegCity;
            let postLiveStreet = form_Data.reprRegStreet;
            let postLiveHouse = form_Data.reprRegHouse;
            let postLiveCorpus = form_Data.reprRegCorpus;
            let postLiveApp = form_Data.reprRegApp;
            let postLivePostalCode = form_Data.reprPostalCode;
            set_Form_Data({
                ...form_Data,
                'postLiveRegion': postLiveRegion,
                'postLiveCity': postLiveCity,
                'postLiveStreet': postLiveStreet,
                'postLiveHouse': postLiveHouse,
                'postLiveCorpus': postLiveCorpus,
                'postLiveApp': postLiveApp,
                'postLivePostalCode': postLivePostalCode,
            });
        } else {
            let postLiveRegion = form_Data.postLiveRegion ? form_Data.postLiveRegion : '';
            let postLiveCity = form_Data.postLiveCity ? form_Data.postLiveCity : '';
            let postLiveStreet = form_Data.postLiveStreet ? form_Data.postLiveStreet  : '';
            let postLiveHouse = form_Data.postLiveHouse ? form_Data.postLiveHouse : '';
            let postLiveCorpus = form_Data.postLiveCorpus ? form_Data.postLiveCorpus : '';
            let postLiveApp = form_Data.postLiveApp ? form_Data.postLiveApp : '';
            let postLivePostalCode = form_Data.postLivePostalCode ? form_Data.postLivePostalCode : '';
            set_Form_Data({
                ...form_Data,
                'postLiveRegion': postLiveRegion,
                'postLiveCity': postLiveCity,
                'postLiveStreet': postLiveStreet,
                'postLiveHouse': postLiveHouse,
                'postLiveCorpus': postLiveCorpus,
                'postLiveApp': postLiveApp,
                'postLivePostalCode': postLivePostalCode,
            });
        }
    };

    return ( <>
        <CardText className="mt-5">Основные сведения</CardText>
        <Card>
            <CardBody>
                <Row>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationreprFio">
                        <FormLabel className='required'>Фамилия</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprFio"
                            value={form_Data.reprFio}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-']+$"
                            className='w-100 legal-form-1'
                            required
                            placeholder='Фамилия'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-]+$/.test(form_Data.reprFio)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationrepName">
                        <FormLabel className='required'>Имя</FormLabel>
                        <Form.Control
                            type="text"
                            name="repName"
                            value={form_Data.repName}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-']+$"
                            className='w-100 legal-form-1'
                            required
                            placeholder='Имя'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-]+$/.test(form_Data.repName)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationreprPatronimic">
                        <FormLabel className='required'>Отчество</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprPatronimic"
                            value={form_Data.reprPatronimic}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-']+$"
                            className='w-100 legal-form-1'
                            required
                            placeholder='Отчество'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-]+$/.test(form_Data.reprPatronimic)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validatiodateAccessStart">
                        <FormLabel className='required'>Дата рождения</FormLabel>
                        <Form.Control
                            type="date"
                            name="dateDateBirthday"
                            onChange={chngFn}
                            max={new Date().toISOString().slice(0, 10)}
                            defaultValue={form_Data.dateDateBirthday}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Дата рождения'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationreprPhone">
                        <FormLabel className='required'>Телефон</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprPhone"
                            value={form_Data.reprPhone}
                            onChange={chngFn}
                            pattern="^\+[0-9]{9,15}$"
                            className='w-100 legal-form-1'
                            required
                            placeholder='+7'
                            isInvalid={ validated && !/^\+[0-9]{9,15}$/.test(form_Data.reprPhone)}
                        />
                        <Form.Control.Feedback type="invalid">
                        Телефон должен быть в формате +7
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={9} as={Col} className="mb-3" controlId="validationreprEmail">
                        <FormLabel className='required'>Электронная почта</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprEmail"
                            value={form_Data.reprEmail}
                            onChange={chngFn}
                            pattern="^\S+@\S+\.\S+$"
                            className='w-100 legal-form-1'
                            required
                            placeholder='Электронная почта'
                            isInvalid={ validated && !/^\S+@\S+\.\S+$/.test(form_Data.reprEmail)}
                        />
                        <Form.Control.Feedback type="invalid">
                        Введите корректную почту
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <div className="formGroupInsideTitle my-4">Документы</div>
                <Row>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationreprInn">
                        <FormLabel className='required'>ИНН</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprInn"
                            value={form_Data.reprInn}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-']+$"
                            className='w-100 legal-form-1'
                            required
                            placeholder='ИНН'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-]+$/.test(form_Data.reprInn)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationreprSNILS">
                        <FormLabel className='required'>СНИЛС</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprSNILS"
                            value={form_Data.reprSNILS}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-']+$"
                            className='w-100 legal-form-1'
                            required
                            placeholder='СНИЛС'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-]+$/.test(form_Data.reprSNILS)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group lg={9} as={Col} className="mb-3" controlId="validationreprBirthdayPlace">
                        <FormLabel className='required'>Место рождения</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprBirthdayPlace"
                            value={form_Data.reprBirthdayPlace}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-']+$"
                            className='w-100 legal-form-1'
                            required
                            placeholder='Место рождения'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-]+$/.test(form_Data.reprBirthdayPlace)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationreprNationality">
                        <FormLabel className='required'>Гражданство</FormLabel>
                        <Form.Select
                            name="reprNationality"
                            className='w-100 legal-form-1'
                            required
                        >
                            <option selected value="RF">Российская Федерация</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationreprPasport">
                        <FormLabel className='required'>Документ, удостоверяющий личность</FormLabel>
                        <Form.Select
                            name="reprPasport"
                            className='w-100 legal-form-1'
                            required
                        >
                            <option selected value="Pasport">Паспорт</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationreprSerial">
                        <FormLabel>Серия</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprSerial"
                            value={form_Data.reprSerial}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            placeholder='Серия'
                            isInvalid={ validated }
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationReprPasportNumber">
                        <FormLabel >Номер</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprPasportNumber"
                            value={form_Data.reprPasportNumber}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            placeholder='Номер'
                            isInvalid={ validated }
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validatiodateAccessStart">
                        <FormLabel className='required'>Дата выдачи</FormLabel>
                        <Form.Control
                            type="date"
                            name="pasportDate"
                            onChange={chngFn}
                            max={new Date().toISOString().slice(0, 10)}
                            defaultValue={form_Data.pasportDate}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Дата выдачи'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationReprPasportNumber">
                        <FormLabel >Код подразделения</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprCodeDepartment"
                            value={form_Data.reprCodeDepartment}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            placeholder='Код подразделения'
                            isInvalid={ validated }
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={9} as={Col} className="mb-3" controlId="validationreprIssuedBy">
                        <FormLabel className='required'>Кем выдан</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprIssuedBy"
                            value={form_Data.reprIssuedBy}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-']+$"
                            className='w-100 legal-form-1'
                            required
                            placeholder='Кем выдан'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9-]+$/.test(form_Data.reprIssuedBy)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Form.Group as={Col} className="mb-3" controlId="validationLicensePasportDoc">
                    <Form.Control 
                        type="file"
                        className="hidden"
                        onChange={uploadHandleChnagePasportDoc}
                        accept=".pdf, .jpg, .jpeg, .png, .heic"
                        required={requiredPasportDoc}
                        name="uploadPasportDoc"
                        ref={filePickerLicensePasportDoc}
                        disabled={!requiredPasportDoc}
                    />
                    
                    <div className="filePicker d-flex align-items-center">
                        <div className="me-4 fileIcon" onClick={handlePickPasportDoc}><img src={selectFile} alt="Select file"/></div>
                        <div className="fileLable">{filenamePasportDoc}
                            <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                        </div>
                        
                        {fileNameCopyScaDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameCopyScaDB} находится в обработке</Alert> : ''}
                        
                        {errorFileSizePasportDoc  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                    </div>
                    <Form.Control.Feedback type="invalid">
                        Файл обязателен к загрузке
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="formGroupInsideTitle my-4">Адрес регистрации по паспорту</div>
                <Row>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationReprRegRegion">
                        <FormLabel className='required'>Регион</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprRegRegion"
                            value={form_Data.reprRegRegion}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Регион'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationReprRegCity">
                        <FormLabel className='required'>Населенный пункт</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprRegCity"
                            value={form_Data.reprRegCity}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Населенный пункт'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group lg={4} as={Col} className="mb-3" controlId="validationReprRegStreet">
                        <FormLabel className='required'>Улица</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprRegStreet"
                            value={form_Data.reprRegStreet}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Улица'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={2} as={Col} className="mb-3" controlId="validationReprRegHouse">
                        <FormLabel className='required'>Дом</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprRegHouse"
                            value={form_Data.reprRegHouse}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Дом'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={2} as={Col} className="mb-3" controlId="validationReprRegCorpus">
                        <FormLabel >Корпус</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprRegCorpus"
                            value={form_Data.reprRegCorpus}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            placeholder='Корпус'
                        />
                    </Form.Group>
                    <Form.Group lg={2} as={Col} className="mb-3" controlId="validationReprRegApp">
                        <FormLabel>Квартира</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprRegApp"
                            value={form_Data.reprRegApp}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            placeholder='Квартира'
                        />
                    </Form.Group>
                    <Form.Group lg={2} as={Col} className="mb-3" controlId="validationReprPostalCode">
                        <FormLabel className='required'>Индекс</FormLabel>
                        <Form.Control
                            type="text"
                            name="reprPostalCode"
                            value={form_Data.reprPostalCode}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Индекс'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Form.Group as={Col} className="mb-3" controlId="validationLicenseRegistrationDoc">
                    <Form.Control 
                        type="file"
                        className="hidden"
                        onChange={uploadHandleChnageRegistrationDoc}
                        accept=".pdf, .jpg, .jpeg, .png, .heic"
                        required={requiredRegistrationDoc}
                        name="uploadRegistrationDoc"
                        ref={filePickerLicenseRegistrationDoc}
                        disabled={!requiredRegistrationDoc}
                    />
                    
                    <div className="filePicker d-flex align-items-center">
                        <div className="me-4 fileIcon" onClick={handlePickRegistrationDoc}><img src={selectFile} alt="Select file"/></div>
                        <div className="fileLable">{filenameRegistrationDoc}
                            <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                        </div>
                        
                        {fileNameregistrationDocDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameregistrationDocDB} находится в обработке</Alert> : ''}
                        
                        {errorFileSizeRegistrationDoc  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                    </div>
                    <Form.Control.Feedback type="invalid">
                        Файл обязателен к загрузке
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="formGroupInsideTitle my-4">Адрес проживания</div>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Совпадает с адресом регистрации</FormLabel>
                    <Form.Check
                        type="radio"
                        name="isLiveAddSameAsReg"
                        value={radioLegalStatus || 'false'}
                        checked={radioLegalStatus === 'true' ? true : false}
                        onChange={e => handleRadioLegalChange('true', 'isLiveAddSameAsReg')}
                        label="Совпадает"
                        id="isLiveAddSameAsRegTrue"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="isLiveAddSameAsReg"
                        value={radioLegalStatus || 'false'}
                        checked={radioLegalStatus === 'true' ? false : true}
                        onChange={e => handleRadioLegalChange('false', 'isLiveAddSameAsReg')}
                        label="Не Совпадает"
                        id="isLiveAddSameAsRegFalse"
                        className="radio"
                    />
                </Form.Group>
                <Row className={radioLegalStatus === 'true' ? 'legalAddressContainer hidden' : 'legalAddressContainer'}>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationAddrLiveRegion">
                        <FormLabel className='required'>Регион</FormLabel>
                        <Form.Control
                            type="text"
                            name="addrLiveRegion"
                            value={form_Data.addrLiveRegion}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Регион'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationAddrLiveCity">
                        <FormLabel className='required'>Населенный пункт</FormLabel>
                        <Form.Control
                            type="text"
                            name="addrLiveCity"
                            value={form_Data.addrLiveCity}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Населенный пункт'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                        <Form.Group lg={4} as={Col} className="mb-3" controlId="validationAddrLiveStreet">
                            <FormLabel className='required'>Улица</FormLabel>
                            <Form.Control
                                type="text"
                                name="addrLiveStreet"
                                value={form_Data.addrLiveStreet}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required
                                placeholder='Улица'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationAddrLiveHouse">
                            <FormLabel className='required'>Дом</FormLabel>
                            <Form.Control
                                type="text"
                                name="addrLiveHouse"
                                value={form_Data.addrLiveHouse}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required
                                placeholder='Дом'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationAddrLiveCorpus">
                            <FormLabel >Корпус</FormLabel>
                            <Form.Control
                                type="text"
                                name="addrLiveCorpus"
                                value={form_Data.addrLiveCorpus}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                placeholder='Корпус'
                            />
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationAddrLiveApp">
                            <FormLabel>Квартира</FormLabel>
                            <Form.Control
                                type="text"
                                name="addrLiveApp"
                                value={form_Data.addrLiveApp}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                placeholder='Квартира'
                            />
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationLegalPostalCode">
                            <FormLabel className='required'>Индекс</FormLabel>
                            <Form.Control
                                type="text"
                                name="addrLivePostalCode"
                                value={form_Data.addrLivePostalCode}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required
                                placeholder='Индекс'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Row>
                <div className="formGroupInsideTitle my-4">Почтовый адрес</div>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Совпадает с aдресом государственной регистрации</FormLabel>
                    <Form.Check
                        type="radio"
                        name="isPostAddSameAsReg"
                        value={radioPostStatus || 'false'}
                        checked={radioPostStatus === 'true' ? true : false}
                        onChange={e => handleRadioPostChange('true', 'isPostAddSameAsReg')}
                        label="Совпадает"
                        id="isPostAddSameAsRegTrue"
                        className="radio"
                    />
                    
                    <Form.Check
                        type="radio"
                        name="isPostAddSameAsReg"
                        value={radioPostStatus || 'false'}
                        checked={radioPostStatus === 'true' ? false : true}
                        onChange={e => handleRadioPostChange('false', 'isPostAddSameAsReg')}
                        label="Не Совпадает"
                        id="isPostAddSameAsRegFalse"
                        className="radio"
                    />
                </Form.Group>
                <Row className={radioPostStatus === 'true' ? 'postAddressContainer hidden' : 'postAddressContainer'}>
                <Form.Group lg={6} as={Col} className="mb-3" controlId="validationPostLiveRegion">
                        <FormLabel className='required'>Регион</FormLabel>
                        <Form.Control
                            type="text"
                            name="postLiveRegion"
                            value={form_Data.postLiveRegion}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Регион'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationAddrLiveCity">
                        <FormLabel className='required'>Населенный пункт</FormLabel>
                        <Form.Control
                            type="text"
                            name="postLiveCity"
                            value={form_Data.postLiveCity}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Населенный пункт'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                        <Form.Group lg={4} as={Col} className="mb-3" controlId="validationPostLiveStreet">
                            <FormLabel className='required'>Улица</FormLabel>
                            <Form.Control
                                type="text"
                                name="postLiveStreet"
                                value={form_Data.postLiveStreet}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required
                                placeholder='Улица'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationPostLiveHouse">
                            <FormLabel className='required'>Дом</FormLabel>
                            <Form.Control
                                type="text"
                                name="postLiveHouse"
                                value={form_Data.postLiveHouse}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required
                                placeholder='Дом'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationPostLiveCorpus">
                            <FormLabel >Корпус</FormLabel>
                            <Form.Control
                                type="text"
                                name="postLiveCorpus"
                                value={form_Data.postLiveCorpus}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                placeholder='Корпус'
                            />
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationPostLiveApp">
                            <FormLabel>Квартира</FormLabel>
                            <Form.Control
                                type="text"
                                name="postLiveApp"
                                value={form_Data.postLiveApp}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                placeholder='Квартира'
                            />
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationPostPostalCode">
                            <FormLabel className='required'>Индекс</FormLabel>
                            <Form.Control
                                type="text"
                                name="postLivePostalCode"
                                value={form_Data.postLivePostalCode}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required
                                placeholder='Индекс'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Row>
            </CardBody>
        </Card>
    </> );
}
 
export default RepresentativeForm1;