import $api from "../http";
import {AxiosResponse} from 'axios';
import {BankResponse} from '../models/response/BankResponse';

export default class BankService {
    static async saveBank(
        email: string,
        user: string,
        currentBankAccount: string,
        bankName: string,
        bankBIK: string,
        corrAccount: string,
        bankInnCode: string,
        bankKPP: string,
        platformRole: string,
        uploadGosReg: File,
        uploadFounderDoc: File,
        ownerMoney: boolean, 
        mainJobMoney: boolean,
        otherMoney: boolean,
        otherMoneyInfo:string,
        otherIncomeMoney: boolean,
        investorMoney: boolean,
        gorvementMoney: boolean,
        platformBuy: boolean,
        platformRedeem: boolean,
        platformSellBuy: boolean,
        platformChange: boolean,
        platformOther: boolean,
        platformGift: boolean,
        otherPlatformInfo: string,
        mainRoleIncome: boolean,
        mainRoleNotIncome: boolean,
        mainRoleProject: boolean,
        mainRoleCharity: boolean
        
        ): Promise<AxiosResponse<BankResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('currentBankAccount', currentBankAccount);
        formData.append('bankName', bankName);
        formData.append('bankBIK', bankBIK);
        formData.append('corrAccount', corrAccount);
        formData.append('bankInnCode', bankInnCode);
        formData.append('bankKPP', bankKPP);
        formData.append('platformRole', platformRole);
        formData.append('uploadGosReg', uploadGosReg);
        formData.append('uploadFounderDoc', uploadFounderDoc);
        formData.append('ownerMoney', ownerMoney.toString());
        formData.append('mainJobMoney', mainJobMoney.toString());
        formData.append('otherMoney', otherMoney.toString());
        formData.append('otherMoneyInfo', otherMoneyInfo);
        formData.append('otherIncomeMoney', otherIncomeMoney.toString());
        formData.append('investorMoney', investorMoney.toString());
        formData.append('gorvementMoney', gorvementMoney.toString());

        formData.append('platformBuy', platformBuy.toString());
        formData.append('platformRedeem', platformRedeem.toString());
        formData.append('platformSellBuy', platformSellBuy.toString());
        formData.append('platformChange', platformChange.toString());
        formData.append('platformOther', platformOther.toString());
        formData.append('platformGift', platformGift.toString());
        formData.append('otherPlatformInfo', otherPlatformInfo);
        formData.append('mainRoleIncome', mainRoleIncome.toString());
        formData.append('mainRoleNotIncome', mainRoleNotIncome.toString());
        formData.append('mainRoleProject', mainRoleProject.toString());
        formData.append('mainRoleCharity', mainRoleCharity.toString());

        return $api.post<BankResponse>('/bankForm', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }
}