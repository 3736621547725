import { FC, useState, useContext, useRef } from "react";
import { Alert, Card, CardBody, CardText, Col, Form, FormLabel, Row } from "react-bootstrap";
import {Context} from "../../../index";
import selectFile from "../../../img/icons/fileUpload.svg";


const LeagalForm4: FC = () => {

    const {store} = useContext(Context);
    const [radioPlatformRoleStatus, setPlatformRoleStatus] = useState<string>(store.bank.platformRole ? store.bank.platformRole : '');
    const validated = false;
    const fileLimit = 10000000;

    // Data for Справка о гос.регистрации file
    const filePickerBankGosReg = useRef<HTMLInputElement>(null);
    const [errorFileSizeGosReg, setErrorGosReg] = useState<boolean>(false);
    const [filenameGosReg, setFilenameGosReg] = useState<string>('Загрузить скан-копию справки');
    const uploadGosRegDBelemnt = store.bank.uploadGosRegPath;

    // Data for Сведения о деловой репутации/репутация file
    const filePickerBankFounderDoc = useRef<HTMLInputElement>(null);
    const [errorFileSizeFounderDoc, setErrorFounderDoc] = useState<boolean>(false);
    const [filenameFounderDoc, setFilenameFounderDoc] = useState<string>('Загрузить скан-копию документов');
    const uploadFounderDocDBelemnt = store.bank.uploadFounderDocPath;

    
    
    const [form_Data, set_Form_Data] = useState({
        email: store.bank.email,
        currentBankAccount: store.bank.currentBankAccount,
        bankName: store.bank.bankName,
        bankBIK: store.bank.bankBIK,
        corrAccount: store.bank.corrAccount,
        bankInnCode: store.bank.bankInnCode,
        bankKPP: store.bank.bankKPP,
        platformRole: store.bank.platformRole,
        uploadGosReg: store.bank.uploadGosReg,
        uploadGosRegPath: uploadGosRegDBelemnt,
        uploadFounderDoc: store.bank.uploadFounderDoc,
        uploadFounderDocPath: uploadFounderDocDBelemnt,
        otherMoneyInfo: store.bank.otherMoneyInfo,
        otherPlatformInfo: store.bank.otherPlatformInfo
    });

    const fileNameGosRegDB = uploadGosRegDBelemnt ? uploadGosRegDBelemnt.split('-f-n-').pop() : '';
    const requiredGosReg:boolean = uploadGosRegDBelemnt ? false : true;

    const fileNameFounderDocDB = uploadFounderDocDBelemnt ? uploadFounderDocDBelemnt.split('-f-n-').pop() : '';
    const requiredFounderDoc:boolean = uploadFounderDocDBelemnt ? false : true;

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    const handleCheckboxChange = (score: any, inputName: string) => {
        setPlatformRoleStatus(score);

        set_Form_Data({
            ...form_Data,
            [inputName]: score,
        });
    };

    const uploadHandleChnageGosReg = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorGosReg(true);
            
            if (filePickerBankGosReg.current) {
                filePickerBankGosReg.current.value = "";
            }
        } else {
            setFilenameGosReg(e.target.files[0].name);
            setErrorGosReg(false);
        }
    }

    const handlePickGosReg = () => {
        if (filePickerBankGosReg.current) {
            filePickerBankGosReg.current.click();
        }
    }

    const uploadHandleChnageFounderDoc = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorFounderDoc(true);
            
            if (filePickerBankFounderDoc.current) {
                filePickerBankFounderDoc.current.value = "";
            }
        } else {
            setFilenameFounderDoc(e.target.files[0].name);
            setErrorFounderDoc(false);
        }
    }

    const handlePickFounderDoc = () => {
        if (filePickerBankFounderDoc.current) {
            filePickerBankFounderDoc.current.click();
        }
    }

    const [form_DataChecbox, set_Form_DataChecbox] = useState({
        ownerMoney: store.bank.ownerMoney || false,
        mainJobMoney: store.bank.mainJobMoney || false,
        otherMoney: store.bank.otherMoney || false,
        otherIncomeMoney: store.bank.otherIncomeMoney || false,
        investorMoney: store.bank.investorMoney || false,
        gorvementMoney: store.bank.gorvementMoney || false,
        
        platformBuy: store.bank.platformBuy || false,
        platformRedeem: store.bank.platformRedeem || false,
        platformSellBuy: store.bank.platformSellBuy || false,
        platformChange: store.bank.platformChange || false,
        platformOther: store.bank.platformOther || false,
        platformGift: store.bank.platformGift || false,

        mainRoleIncome: store.bank.mainRoleIncome || false,
        mainRoleNotIncome: store.bank.mainRoleNotIncome || false,
        mainRoleProject: store.bank.mainRoleProject || false,
        mainRoleCharity: store.bank.mainRoleCharity || false

    });

    const [showHiddenInputMoney, setShowHiddenInputMoney] = useState<boolean>(store.bank.otherMoney || false);
    const [showHiddenInputPlatform, setShowHiddenInputPlatform] = useState<boolean>(store.bank.platformOther || false);
    const handleCheckboxMoney = (score: any, inputName: string) => {
        set_Form_DataChecbox({
            ...form_DataChecbox,
            [inputName]: score,
        });
        if (inputName === 'otherMoney') {
            setShowHiddenInputMoney(score);
        }
        if (inputName === 'platformOther') {
            setShowHiddenInputPlatform(score);
        }
    };

    return ( <>
        <CardText className="mt-5">Сведения об источниках происхождения денежных средств</CardText>
        <Card>
            <CardBody>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Сведения об источниках происхождения денежных средств</FormLabel>
                    <Form.Check
                        type="checkbox"
                        name="ownerMoney"
                        label="Финансирование учредителей (акционеров, участников)"
                        id="ownerMoney"
                        className="radio"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.ownerMoney, 'ownerMoney')}
                        checked={form_DataChecbox.ownerMoney}
                    />
                    <Form.Check
                        type="checkbox"
                        name="mainJobMoney"
                        label="Доходы от основного вида деятельности"
                        id="mainJobMoney"
                        className="radio"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.mainJobMoney, 'mainJobMoney')}
                        checked={form_DataChecbox.mainJobMoney}
                    />
                    <Form.Check
                        type="checkbox"
                        name="otherIncomeMoney"
                        label="Доходы от дополнительных видов деятельности"
                        id="otherIncomeMoney"
                        className="radio"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.otherIncomeMoney, 'otherIncomeMoney')}
                        checked={form_DataChecbox.otherIncomeMoney}
                    />
                    <Form.Check
                        type="checkbox"
                        name="investorMoney"
                        label="Заемные (привлеченные) денежные средства"
                        id="investorMoney"
                        className="radio"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.investorMoney, 'investorMoney')}
                        checked={form_DataChecbox.investorMoney}
                    />
                    <Form.Check
                        type="checkbox"
                        name="gorvementMoney"
                        label="Государственное финансирование"
                        id="gorvementMoney"
                        className="radio"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.gorvementMoney, 'gorvementMoney')}
                        checked={form_DataChecbox.gorvementMoney}
                    />
                    <Form.Check
                        type="checkbox"
                        name="otherMoney"
                        label="Иное"
                        id="otherMoney"
                        className="radio"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.otherMoney, 'otherMoney')}
                        checked={form_DataChecbox.otherMoney}
                    />
                </Form.Group>
                <Form.Group as={Col} className={showHiddenInputMoney ? 'mt-3' : 'd-none'} controlId="validationCurrentBankAccount">
                    <FormLabel className={showHiddenInputMoney ? 'required' : ''}>Иное</FormLabel>
                    <Form.Control
                        type="text"
                        name="otherMoneyInfo"
                        value={form_Data.otherMoneyInfo}
                        onChange={chngFn}
                        pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                        className='w-100'
                        required={showHiddenInputMoney ? true : false}
                        placeholder='Укажите альтернативный источник происхождения денежных средств организации'
                        isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.otherMoneyInfo)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Введите корректные данные
                    </Form.Control.Feedback>
                </Form.Group>
            </CardBody>
            <CardBody>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Планируемые операции на платформе</FormLabel>
                    <Form.Check
                        type="checkbox"
                        name="platformBuy"
                        label="Приобретение ЦФА (в том числе гибридных цифровых прав) при их выпуске"
                        id="platformBuy"
                        className="radio"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.platformBuy, 'platformBuy')}
                        checked={form_DataChecbox.platformBuy}
                    />
                    <Form.Check
                        type="checkbox"
                        name="platformRedeem"
                        label="Погашение, в том числе досрочное ЦФА (включая гибридные цифровые права)"
                        id="platformRedeem"
                        className="radio"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.platformRedeem, 'platformRedeem')}
                        checked={form_DataChecbox.platformRedeem}
                    />
                    <Form.Check
                        type="checkbox"
                        name="platformSellBuy"
                        label="Купля - продажа ЦФА"
                        id="platformSellBuy"
                        className="radio"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.platformSellBuy, 'platformSellBuy')}
                        checked={form_DataChecbox.platformSellBuy}
                    />
                    <Form.Check
                        type="checkbox"
                        name="platformChange"
                        label="Обмен ЦФА"
                        id="platformChange"
                        className="radio"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.platformChange, 'platformChange')}
                        checked={form_DataChecbox.platformChange}
                    />
                    <Form.Check
                        type="checkbox"
                        name="platformGift"
                        label="Передача ЦФА в случае использования в качестве встречного предоставления за передаваемые товары, информацию и результаты интеллектуальной деятельности, в том числе исключительные права 
                        на них, по внешнеторговым договорам, заключенным между резидентами и нерезидентами"
                        id="platformGift"
                        className="radio d-flex align-items-center"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.platformGift, 'platformGift')}
                        checked={form_DataChecbox.platformGift}
                    />
                    <Form.Check
                        type="checkbox"
                        name="platformOther"
                        label="Иное"
                        id="platformOther"
                        className="radio"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.platformOther, 'platformOther')}
                        checked={form_DataChecbox.platformOther}
                    />
                </Form.Group>
                <Form.Group as={Col} className={showHiddenInputPlatform ? 'mt-3' : 'd-none'} controlId="validationCurrentBankAccount">
                    <FormLabel className={showHiddenInputPlatform ? 'required' : ''}>Иное</FormLabel>
                    <Form.Control
                        type="text"
                        name="otherPlatformInfo"
                        value={form_Data.otherPlatformInfo}
                        onChange={chngFn}
                        pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                        className='w-100'
                        required={showHiddenInputPlatform ? true : false}
                        placeholder='Укажите альтернативный источник происхождения денежных средств организации'
                        isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.otherPlatformInfo)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Введите корректные данные
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} className="mb-3 mt-5">
                    <FormLabel>Сведения о целях финансово-хозяйственной деятельности</FormLabel>
                    <Form.Check
                        type="checkbox"
                        name="mainRoleIncome"
                        label="Получение прибыли"
                        id="mainRoleIncome"
                        className="radio"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.mainRoleIncome, 'mainRoleIncome')}
                        checked={form_DataChecbox.mainRoleIncome}
                    />
                    <Form.Check
                        type="checkbox"
                        name="mainRoleNotIncome"
                        label="Общественно-полезная деятельность без извлечения прибыли"
                        id="mainRoleNotIncome"
                        className="radio"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.mainRoleNotIncome, 'mainRoleNotIncome')}
                        checked={form_DataChecbox.mainRoleNotIncome}
                    />
                    <Form.Check
                        type="checkbox"
                        name="mainRoleProject"
                        label="Реализация общественных проектов"
                        id="mainRoleProject"
                        className="radio"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.mainRoleProject, 'mainRoleProject')}
                        checked={form_DataChecbox.mainRoleProject}
                    />
                    <Form.Check
                        type="checkbox"
                        name="mainRoleCharity"
                        label="Благотворительность"
                        id="mainRoleCharity"
                        className="radio"
                        onChange={e => handleCheckboxMoney(!form_DataChecbox.mainRoleCharity, 'mainRoleCharity')}
                        checked={form_DataChecbox.mainRoleCharity}
                    />
                </Form.Group>
            </CardBody>
        </Card>
        <CardText className="mt-5">Банковские реквизиты</CardText>
        <Card>
            <CardBody>
                <Form.Group as={Col} className="mb-3" controlId="validationCurrentBankAccount">
                    <FormLabel className='required'>Расчетный счет</FormLabel>
                    <Form.Control
                        type="text"
                        name="currentBankAccount"
                        value={form_Data.currentBankAccount}
                        onChange={chngFn}
                        pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                        className='w-100'
                        required
                        placeholder='Расчетный счет'
                        isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.currentBankAccount)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Введите корректные данные
                    </Form.Control.Feedback>
                </Form.Group>
                <Row>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationBankName">
                        <FormLabel className='required'>Наименование банка</FormLabel>
                        <Form.Control
                            type="text"
                            name="bankName"
                            value={form_Data.bankName}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                            className='w-100'
                            required
                            placeholder='Наименование банка'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.bankName)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationCurrentBankAccount">
                        <FormLabel className='required'>БИК банка</FormLabel>
                        <Form.Control
                            type="text"
                            name="bankBIK"
                            value={form_Data.bankBIK}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                            className='w-100'
                            required
                            placeholder='БИК банка'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.bankBIK)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationCorrAccount">
                        <FormLabel className='required'>Корреспондентский счет</FormLabel>
                        <Form.Control
                            type="text"
                            name="corrAccount"
                            value={form_Data.corrAccount}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                            className='w-100'
                            required
                            placeholder='Корреспондентский счет'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.corrAccount)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationBankName">
                        <FormLabel className='required'>ИНН Банка</FormLabel>
                        <Form.Control
                            type="text"
                            name="bankInnCode"
                            value={form_Data.bankInnCode}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                            className='w-100'
                            required
                            placeholder='ИНН Банка'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.bankInnCode)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationCurrentBankAccount">
                        <FormLabel className='required'>КПП Банка</FormLabel>
                        <Form.Control
                            type="text"
                            name="bankKPP"
                            value={form_Data.bankKPP}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                            className='w-100'
                            required
                            placeholder='КПП Банка'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.bankKPP)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            </CardBody>
        </Card>
        <CardText className="mt-5">Дополнительная информация о юридическом лице</CardText>
        <Card>
            <CardBody>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Роль на платформе</FormLabel>
                    <Form.Check
                        type="radio"
                        name="platformRole"
                        value="developRole"
                        checked={(radioPlatformRoleStatus === 'developRole' || radioPlatformRoleStatus === '') ? true : false}
                        onChange={e => handleCheckboxChange('developRole', 'platformRole')}
                        label="Производитель"
                        id="platformRoleDevelop"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="platformRole"
                        value="tradeRole"
                        checked={radioPlatformRoleStatus === 'tradeRole' ? true : false}
                        onChange={e => handleCheckboxChange('tradeRole', 'platformRole')}
                        label="Трейдер"
                        id="platformRoleTrade"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="platformRole"
                        value="investRole"
                        checked={radioPlatformRoleStatus === 'investRole' ? true : false}
                        onChange={e => handleCheckboxChange('investRole', 'platformRole')}
                        label="Инвестор"
                        id="platformRoleInvest"
                        className="radio"
                    />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="validationBankGosReg">
                    <FormLabel>Справка о гос.регистрации</FormLabel>
                    <Form.Control 
                        type="file"
                        className="hidden"
                        onChange={uploadHandleChnageGosReg}
                        accept=".pdf, .jpg, .jpeg, .png, .heic"
                        required={requiredGosReg}
                        name="uploadGosReg"
                        ref={filePickerBankGosReg}
                        disabled={!requiredGosReg}
                    />
                    
                    <div className="filePicker d-flex align-items-center">
                        <div className="me-4 fileIcon" onClick={handlePickGosReg}><img src={selectFile} alt="Select file"/></div>
                        <div className="fileLable">{filenameGosReg}
                            <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                        </div>
                        
                        {fileNameGosRegDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameGosRegDB} находится в обработке</Alert> : ''}
                        
                        {errorFileSizeGosReg  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                    </div>
                    <Form.Control.Feedback type="invalid">
                        Файл обязателен к загрузке
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="validationBankFounderDoc">
                    <FormLabel>Учредительные документы</FormLabel>
                    <Form.Control 
                        type="file"
                        className="hidden"
                        onChange={uploadHandleChnageFounderDoc}
                        accept=".pdf, .jpg, .jpeg, .png, .heic"
                        required={requiredFounderDoc}
                        name="uploadFounderDoc"
                        ref={filePickerBankFounderDoc}
                        disabled={!requiredFounderDoc}
                    />
                    
                    <div className="filePicker d-flex align-items-center">
                        <div className="me-4 fileIcon" onClick={handlePickFounderDoc}><img src={selectFile} alt="Select file"/></div>
                        <div className="fileLable">{filenameFounderDoc}
                            <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                        </div>
                        
                        {fileNameFounderDocDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameFounderDocDB} находится в обработке</Alert> : ''}
                        
                        {errorFileSizeFounderDoc  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                    </div>
                    <Form.Control.Feedback type="invalid">
                        Файл обязателен к загрузке
                    </Form.Control.Feedback>
                </Form.Group>
            </CardBody>
        </Card>
    </> );
}
 
export default LeagalForm4;