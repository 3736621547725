import {FC, useContext, useState} from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../../index";
import CfaAccountSubMenu from '../account/parts/CfaAccountSubMenu';
import { Button, Card, CardBody, CardTitle, Col, Container, Modal, Row } from 'react-bootstrap';
import emitImage from "../../img/icons/emiter-default.svg"
import {useParams } from 'react-router-dom';
import download from "../../img/icons/download.svg";
import ReleaseCfaService from '../../services/ReleaseCfaService';
import { useQuery } from '@tanstack/react-query';

const MyCfaDetail:FC = () => {
    const {store} = useContext(Context);
    const params = useParams();
    let cfaID:any = params.cfaID;
    const cfaItem = store.myCfa.myCfaInfo;
    let myCfa:any = {}
    if (cfaItem) {
        for (let index = 0; index < cfaItem.length; index++) {
            const element = cfaItem[index];
            if (element._id === cfaID) {
                myCfa = element;
            }
        }
    }

    const getMyCfaList = async () => {
        try {
            const response = await ReleaseCfaService.getDetailCFA(myCfa.emitID, myCfa.cfaID);
            if (response.data && response.data.ticker === undefined) {
                console.log('Response error');
                return false;
            } else {
                return response.data;
            }
        } catch (e: any) {
            // message gets narrowed to string!s
            console.log(e.response);
        }
    }

    const {data} = useQuery({
        queryKey: ['myCfaListDeatil'],
        queryFn: getMyCfaList,
    })

    const cfaItemFromRelease = data;
    const [show, setShow] = useState (false);
    const handleClickModal = () => (setShow(true));
    const handleClose = () => setShow(false);

    return (<>
            {store.isAuth && store.user.isActivated ? (<>
                <CfaAccountSubMenu class1="organization-link px-4 active" class2="organization-link px-4" class3="organization-link px-4"/>
                <Container className='mb-5'>
                    {store.errorMessage !== 'empty_cfa' ? (<>
                        <div className='dashboard-cfa mt-5'>
                            <Row className="cfa-table-header mt-5 me-0 ms-0">
                                <Col lg={4}>Наименование</Col>
                                <Col lg={3}>Эмитент</Col>
                                <Col className="text-center" lg={1}>Количество</Col>
                                <Col className="text-center" lg={1}>Дата погашения</Col>
                                <Col className="text-center" lg={1}>Цена погашения</Col>
                                <Col className="text-center" lg={2}>Сумма</Col>
                                
                            </Row>
                            <Row className="align-items-center cfa-table-body mt-4 me-0 ms-0">
                                <Col className="ps-0" lg={4}>
                                        <div className='cfa-table-title'>
                                            <div className='cfa-table-sub-title'>Digital-Grain_{myCfa.ticker}</div>
                                            <div className='cfa-table-name'>{myCfa.releaseName}</div>
                                        </div>
                                </Col>
                                <Col lg={3} className='align-items-center'><img className="me-3" src={emitImage} alt="Эмитент"/><span>{myCfa.emitName}</span></Col>
                                <Col className="text-center" lg={1}>{myCfa.sendAmount}</Col>
                                <Col className="text-center" lg={1}>{myCfa.dateOfEmition}</Col>
                                <Col className="text-center" lg={1}>{myCfa.emitPrice} &#8381;</Col>
                                <Col className="text-center cmpleate-price-cfa" lg={2}>{myCfa.totalEmitPrice} &#8381;</Col>
                            </Row>
                        </div>
                        <div className='detail-cfa-info mb-3'>
                            <CardTitle className='mt-5'>Детали выпуска</CardTitle>
                            <Button variant="secondary"><img src={download} alt="Скачать"/> Скачать эмиссионную документацию</Button>
                        </div>
                        <Card className="mb-3">
                            <CardBody>
                                <div className='d-flex justify-content-between cfa-table-detail'>
                                    <div className='left d-flex gap-3'>
                                        <div className='cfa-table-title gray-light'>
                                            <div className='cfa-table-name'>{cfaItemFromRelease ? cfaItemFromRelease.dateEmissionCfa : ''}</div>
                                            <div className='cfa-table-sub-title'>дата выпуска ЦФА</div>
                                        </div>
                                        <div className='cfa-table-title gray-light'>
                                            <div className='cfa-table-name'>{cfaItemFromRelease ? cfaItemFromRelease.dateStartShowCfa : ''} <span className='ms-2'>00:00 ч</span></div>
                                            <div className='cfa-table-sub-title'>дата начала размещения ЦФА</div>
                                        </div>
                                        <div className='cfa-table-title gray-dark'>
                                            <div className='cfa-table-name'>{cfaItemFromRelease ? cfaItemFromRelease.dateEndShowCfa : ''} <span className='ms-2'>23:59 ч</span></div>
                                            <div className='cfa-table-sub-title'>дата окончания размещения ЦФА</div>
                                        </div>
                                    </div>
                                    <div className='right d-flex gap-3'>
                                        <div className='cfa-table-title success'>
                                            <div className='cfa-table-name'>{cfaItemFromRelease ? cfaItemFromRelease.countCfaOrigin : ''}</div>
                                            <div className='cfa-table-sub-title'>объем выпуска ЦФА</div>
                                        </div>
                                        <div className='cfa-table-title success'>
                                            <div className='cfa-table-name'>{cfaItemFromRelease ? cfaItemFromRelease.priceCfa : ''} &#8381;</div>
                                            <div className='cfa-table-sub-title'>цена ЦФА при эмиссии</div>
                                        </div>
                                        <div className='cfa-table-title success'>
                                            <div className='cfa-table-name'>{cfaItemFromRelease ? cfaItemFromRelease.ownerType : ''}</div>
                                            <div className='cfa-table-sub-title'>вид прав по ЦФА</div>
                                        </div>
                                    </div>
                                </div>
                                
                            </CardBody>
                        </Card>
                        <Button className="my-3" variant="primary" onClick={handleClickModal}>Продать</Button>
                    </>) : (<div className='text-center'>
                        <h3 className='my-5'>{store.user.name} {store.user.patronymic}, ЦФА не найден! <br/><br/></h3>
                        <p>Данный ЦФА был удален или погашен. Для подробной информации обратитесь в службу поддержки. Спасибо </p>
                    </div>)}

                    <Modal className='cfa-modal' show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <h4 className='mb-3'>Подача заявки на вторичное обращение ЦФА будет осуществлена на сайте партнёра. Нажмите на кнопку "ОК" для продолжения</h4>
                            <div className='text-center'><a onClick={handleClose} className="px-5 my-5 btn btn-primary" href='https://client.prod.tokenique.app/' target='_black'>ОК</a></div>
                        </Modal.Body>
                    </Modal>
                </Container>
            </>) : (<></>)}
        </>
    );
}
 
export default observer(MyCfaDetail);