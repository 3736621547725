import { FC, useState, useContext, useRef } from "react";
import { Alert, Button, Card, CardBody, CardText, Col, Form, FormLabel, Row } from "react-bootstrap";
import {Context} from "../../../index";
import selectFile from "../../../img/icons/fileUpload.svg";
import URL from "../../../config/default.json"

interface LegalForm3Props {
    onChange: (value: boolean, allFiles:any, allPasportFiles:any, allRegistrationFiles:any) => void; // Define the type for onChange prop
  }

const LeagalForm3: FC<LegalForm3Props> = ({ onChange }) => {
    const {store} = useContext(Context);
    const validated = false;
    const fileLimit = 10000000;
    const [radioBeneficialOwnersStatus, setRadioBeneficialOwnersStatus] = useState<string>(store.owner.beneficialOwner ? store.owner.beneficialOwner : '');
    const [partControlOwnerChecked, setPartControlOwnerStatus] = useState<boolean>(store.owner.partControlOwner);
    const [fullCOntrolOwnerChecked, setfullCOntrolOwnerStatus] = useState<boolean>(store.owner.fullCOntrolOwner);

    // Variables for Upload pasport documents
    const filePickerLicensePasportDoc = useRef<HTMLInputElement>(null);
    const [filenamePasportDoc, setFilenamePasportDoc] = useState<string>('Загрузить скан-копию паспорта');
    const uploadPasportDocDBelemnt = store.owner.uploadPasportDocPath;

    // Variables for Upload address documents
    const filePickerLicenseRegistrationDoc = useRef<HTMLInputElement>(null);
    const [filenameRegistrationDoc, setFilenameRegistrationDoc] = useState<string>('Загрузить скан-копию прописки');
    const uploadRegistrationDocDBelemnt = store.owner.uploadRegistrationDocPath;

    const [radioLegalStatus, setRadioLegalStatus] = useState<string>(store.owner.isLiveAddSameAsReg || 'false')
    const [radioPostStatus, setRadioPostStatus] = useState<string>(store.owner.isPostAddSameAsReg || 'false')

    const uploadBenConfirmDBelemnt = store.owner.uploadBenConfirmPath;

    const [form_Data, set_Form_Data] = useState({
        email: store.owner.email,
        organizationalStructureLegalEntity: store.owner.organizationalStructureLegalEntity,
        beneficialOwner: store.owner.beneficialOwner,
        ownerSurname: store.owner.ownerSurname,
        ownerName: store.owner.ownerName,
        ownerPart: store.owner.ownerPart,
        haveIncome: store.owner.haveIncome,
        ownerPartLine: store.owner.ownerPartLine || '',
        benStatus: store.owner.benStatus || '',
        benConfirmDoc: store.owner.benConfirmDoc || '',
        uploadBenConfirmPath: uploadBenConfirmDBelemnt,
        reprFio: store.owner.reprFio,
        repName: store.owner.repName,
        reprPatronimic: store.owner.reprPatronimic,
        dateDateBirthday: store.owner.dateDateBirthday,
        reprEmail: store.owner.reprEmail,
        reprPhone: store.owner.reprPhone,
        reprBirthdayPlace: store.owner.reprBirthdayPlace || '',
        reprSerial: store.owner.reprSerial || '',
        reprPasportNumber: store.owner.reprPasportNumber || '',
        pasportDate: store.owner.pasportDate || '',
        reprCodeDepartment: store.owner.reprCodeDepartment || '',
        reprIssuedBy: store.owner.reprIssuedBy || '',
        reprInn: store.owner.reprInn || '',
        reprRegRegion: store.owner.reprRegRegion || '',
        reprRegCity: store.owner.reprRegCity || '',
        reprRegStreet: store.owner.reprRegStreet || '',
        reprRegHouse: store.owner.reprRegHouse || '',
        reprRegCorpus: store.owner.reprRegCorpus || '',
        reprRegApp: store.owner.reprRegApp || '',
        reprPostalCode: store.owner.reprPostalCode || '',
        isLiveAddSameAsReg: radioLegalStatus,
        isPostAddSameAsReg: radioPostStatus,
        addrLiveRegion: store.owner.addrLiveRegion || '',
        addrLiveCity: store.owner.addrLiveCity || '',
        addrLiveStreet: store.owner.addrLiveStreet || '',
        addrLiveHouse: store.owner.addrLiveHouse || '',
        addrLiveCorpus: store.owner.addrLiveCorpus || '',
        addrLiveApp: store.owner.addrLiveApp || '',
        addrLivePostalCode: store.owner.addrLivePostalCode || '',
        postLiveRegion: store.owner.postLiveRegion || '',
        postLiveCity: store.owner.postLiveCity || '',
        postLiveStreet: store.owner.postLiveStreet || '',
        postLiveHouse: store.owner.postLiveHouse,
        postLiveCorpus: store.owner.postLiveCorpus || '',
        postLiveApp: store.owner.postLiveApp || '',
        postLivePostalCode: store.owner.postLivePostalCode || '',
        benPartLine: store.owner.benPartLine || ''
    });

    const handleRadionChange = (score: any, inputName: string) => {
        if (inputName === 'beneficialOwner') {
            setRadioBeneficialOwnersStatus(score);
        }

        set_Form_Data({
            ...form_Data,
            [inputName]: score,
        });
    };
    const [haveIncomeStatus, sethaveIncomeStatus] = useState<string>(store.owner.haveIncome);

    const handleCheckboxChange = (score: any, inputName: string) => {
        if (inputName === 'partControlOwner') {
            setPartControlOwnerStatus(score);
        } else if (inputName === 'haveIncome'){
            sethaveIncomeStatus(score);
        }
        else {
            setfullCOntrolOwnerStatus(score);
        }
    };

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };
    const [ownerPartLine, setOwnerPartLine] = useState<string>(form_Data.ownerPartLine || '');
    let groupLinefromBD:any = [];
    if (form_Data.ownerPartLine) {
        groupLinefromBD = form_Data.ownerPartLine.split('/-/').slice(0, -1);
    }
    
    let groupLineFinal:string[][] = [];
    for (let i = 0; i < groupLinefromBD.length; i++) {
        groupLineFinal[i] = groupLinefromBD[i].split('/+/');
    }
    
    const [groups, setGroups] = useState<string[][]>(groupLineFinal || []);
    const [maxPart, setMaxPart] = useState<number>(100);

    const addStrings = (): void => {
        const input1 = form_Data.ownerSurname;
        const input2 = form_Data.ownerName;
        const input3 = form_Data.ownerPart;
        const newGroup = [input1.trim(), input2.trim(), input3.trim()].filter(Boolean);
        if (newGroup.length > 0) {
            setMaxPart(maxPart - parseInt(input3));
            let newOwnerLine = ownerPartLine + input1 + '/+/' + input2 + '/+/' + input3 + '/-/';
            setOwnerPartLine(newOwnerLine);
        
            setGroups((prevGroups) => [...prevGroups, newGroup]);
            set_Form_Data({
                ...form_Data,
                ownerSurname: '',
                ownerName: '',
                ownerPart: ''
            });
        }
    };

    const removeGroup = (index: number): void => {
        setGroups((prevGroups) => prevGroups.filter((_, i) => i !== index));
        let newOwnerLine = '';
        for (let i = 0; i < groups.length; i++) {
            const element = groups[i];
            if (i !== index) {
                newOwnerLine = newOwnerLine + element[0] + '/+/' + element[1] + '/+/' + element[2] + '/-/';
            }
        }
        setOwnerPartLine(newOwnerLine);
    };

    const [benPartLine, setBenPartLine] = useState<string>(form_Data.benPartLine || '');
    let groupBenfromBD:any = [];
    if (form_Data.benPartLine) {
        groupBenfromBD = form_Data.benPartLine.split('/-/').slice(0, -1);
    }

    let groupLineFinalBen:string[][] = [];
    for (let i = 0; i < groupBenfromBD.length; i++) {
        groupLineFinalBen[i] = groupBenfromBD[i].split('/+/');
    }

    const [groupsBen, setBenGroups] = useState<string[][]>(groupLineFinalBen || []);

    const addNewBen = (e:any): void => {
        const form = e.currentTarget.closest('form');
        const formvalue:boolean = form.checkValidity();
        window.scrollTo(1000, 1400);
        if (form.checkValidity() === true) {
            onChange(!formvalue, allFiles, allPasportFiles, allRegistrationFiles);
            const benStatus = form_Data.benStatus;
            const benConfirmDoc = form_Data.benConfirmDoc;
            const reprFio = form_Data.reprFio;
            const repName = form_Data.repName;
            const reprPatronimic = form_Data.reprPatronimic;
            const dateDateBirthday = form_Data.dateDateBirthday;
            const reprPhone = form_Data.reprPhone;
            const reprEmail = form_Data.reprEmail;
            const reprInn = form_Data.reprInn;
            const reprBirthdayPlace = form_Data.reprBirthdayPlace;
            const reprNationality = 'Российская Федерация';
            const reprSerial = form_Data.reprSerial;
            const reprPasportNumber = form_Data.reprPasportNumber;
            const pasportDate = form_Data.pasportDate;
            const reprCodeDepartment = form_Data.reprCodeDepartment || 'null';
            const reprIssuedBy = form_Data.reprIssuedBy;
            const reprRegRegion = form_Data.reprRegRegion;
            const reprRegCity = form_Data.reprRegCity || '';
            const reprRegStreet = form_Data.reprRegStreet || '';
            const reprRegHouse = form_Data.reprRegHouse || '';
            const reprPostalCode = form_Data.reprPostalCode || '';
            const isLiveAddSameAsReg = radioLegalStatus;
            const isPostAddSameAsReg = radioPostStatus;

            const newGroup = [benStatus.trim(), benConfirmDoc.trim(), reprFio.trim(), repName.trim(), reprPatronimic.trim(), dateDateBirthday.trim(), reprPhone.trim(), reprEmail.trim(), reprInn.trim(), reprBirthdayPlace.trim(), reprNationality.trim(), reprSerial.trim(), reprPasportNumber.trim(), pasportDate.trim(), reprCodeDepartment.trim(), reprIssuedBy.trim(), reprRegRegion.trim(), reprRegCity.trim(), reprRegStreet.trim(), reprRegHouse.trim(), reprPostalCode.trim(), isLiveAddSameAsReg.trim(), isPostAddSameAsReg.trim()].filter(Boolean);
            if (newGroup.length > 0) {
                let newOwnerLine = benPartLine + benStatus + '/+/' + benConfirmDoc + '/+/' + reprFio + '/+/' + repName + '/+/' + reprPatronimic + '/+/' + dateDateBirthday + '/+/' + reprPhone + '/+/' + reprEmail + '/+/' + reprInn + '/+/' + reprBirthdayPlace + '/+/' + reprNationality + '/+/' + reprSerial + '/+/' + reprPasportNumber + '/+/' + pasportDate + '/+/' + reprCodeDepartment + '/+/' + reprIssuedBy + '/+/' + reprRegRegion + '/+/' + reprRegCity + '/+/' + reprRegStreet + '/+/' + reprRegHouse + '/+/' + reprPostalCode + '/+/' + isLiveAddSameAsReg + '/+/' + isPostAddSameAsReg + '/-/';
                setBenPartLine(newOwnerLine);
                setMaxLenght(newGroup.length);
                setBenGroups((prevGroups) => [...prevGroups, newGroup]);
                set_Form_Data({
                    ...form_Data,
                    benStatus: '',
                    benConfirmDoc: '',
                    reprFio: '',
                    repName: '',
                    reprPatronimic: '',
                    reprPhone: '',
                    reprEmail: '',
                    reprInn: '',
                    reprBirthdayPlace: '',
                    reprSerial: '',
                    reprPasportNumber: '',
                    reprCodeDepartment: '',
                    reprIssuedBy: '',
                    reprRegRegion: '',
                    reprRegCity: '',
                    reprRegStreet: '',
                    reprRegHouse: '',
                    reprPostalCode: ''
                });
            }
        } else {
            onChange(!formvalue, allFiles, allPasportFiles, allRegistrationFiles);
        }
    };

    const removeBenGroup = (index: number): void => {
        setBenGroups((prevGroups) => prevGroups.filter((_, i) => i !== index));
        setAllFiles((prevGroups) => {
            const newMap = new Map(prevGroups); // Create a shallow copy of the Map
            const keys = Array.from(newMap.keys()); // Get all keys as an array
            const keyToRemove = keys[index]; // Find the key at the specified index
            if (keyToRemove !== undefined) {
                newMap.delete(keyToRemove); // Remove the item from the Map
            }
            return newMap; // Return the updated Map
        });
        let newBenLine = '';
        for (let i = 0; i < groupsBen.length; i++) {
            const element = groupsBen[i];
            if (i !== index) {
               newBenLine = newBenLine + element[0] + '/+/' + element[1] + '/+/' + element[2] + '/+/' + element[3] + '/+/' + element[4] + '/+/' + element[5] + '/+/' + element[6] + '/+/' + element[7] + '/+/' + element[8] + '/+/' + element[9] + '/+/' + element[10] + '/+/' + element[11] + '/+/' + element[12] + '/+/' + element[13] + '/+/' + element[14]+ '/+/' + element[15]+ '/+/' + element[16]+ '/+/' + element[17]+ '/+/' + element[18] + '/+/' + element[19] + '/+/' + element[20] + '/+/' + element[21] + '/+/' + element[22] + '/-/';
            }
        }
        setBenPartLine(newBenLine);
    };
    const fileBenConfirmDB = uploadBenConfirmDBelemnt ? uploadBenConfirmDBelemnt.split('-f-n-').pop() : '';
    const filePickerLicenseBenConfirm = useRef<HTMLInputElement>(null);
    const [errorFileSize2, setErrorFileSize2] = useState<boolean>(false);
    const [filenameBenConfirm, setFilenameBenConfirm] = useState<string>('Загрузить скан-копию документов');
    const [errorFileSizeConfirmDoc, setErrorConfirmDoc] = useState<string>('');

    const [allFiles, setAllFiles] = useState(new Map());
    const [allPasportFiles, setPasportAllFiles] = useState(new Map());
    const [errorFileSizePasport, setErrorFileSizePasport] = useState<boolean>(false);
    const [errorPasportDoc, setErrorPasportDoc] = useState<string>('');

    const [allRegistrationFiles, setRegistrationAllFiles] = useState(new Map());
    const [errorFileSizeRegistration, setErrorFileSizeRegistration] = useState<boolean>(false);
    const [errorRegistrationDoc, setErrorRegistrationDoc] = useState<string>('');
    const [maxLenght, setMaxLenght] = useState(1);

    const uploadHandleChnageBenConfirm = (e: any) => {
        let photos = e.target.files;
        var photoName: any[] = [];
        var errOneOfFile:string = '';
        if (photos.length > maxLenght) {
            setErrorFileSize2(true);
            if (filePickerLicenseBenConfirm.current) {
                filePickerLicenseBenConfirm.current.value = "";
            }
        } else {
            setErrorFileSize2(false);
            Array.from(photos).forEach((photo: any) => {
                if (photo.size > fileLimit) {
                    errOneOfFile = photo.name;
                } else {
                    photoName.push(photo.name);
                }
            });
            setErrorConfirmDoc(errOneOfFile);
            setFilenameBenConfirm('Список загруженных документов')
        }

        setAllFiles((prevFiles) => {
            const updatedFiles = new Map(prevFiles);
            for (let file of photos) {
              updatedFiles.set(file.name, file); // Use file name as the key to ensure uniqueness
            }
            onChange(false, updatedFiles, allPasportFiles, allRegistrationFiles);
            return updatedFiles;
        });
        
    }

    const handlePickBenConfirm = () => {
        if (filePickerLicenseBenConfirm.current) {
            filePickerLicenseBenConfirm.current.click();
        }
    }

    const uploadHandleChnagePasportDoc = (e: any) => {
        let photos = e.target.files;
        var photoName: any[] = [];
        var errOneOfFile:string = '';
        if (photos.length > maxLenght) {
            setErrorFileSizePasport(true);
            if (filePickerLicensePasportDoc.current) {
                filePickerLicensePasportDoc.current.value = "";
            }
        } else {
            setErrorFileSizePasport(false);
            Array.from(photos).forEach((photo: any) => {
                if (photo.size > fileLimit) {
                    errOneOfFile = photo.name;
                } else {
                    photoName.push(photo.name);
                }
            });
            setErrorPasportDoc(errOneOfFile);
            setFilenamePasportDoc('Список загруженных документов')
        }

        setPasportAllFiles((prevFiles) => {
            const updatedFiles = new Map(prevFiles);
            for (let file of photos) {
              updatedFiles.set(file.name, file); // Use file name as the key to ensure uniqueness
              onChange(false, allFiles, updatedFiles, allRegistrationFiles);
            }
            return updatedFiles;
        });
    }

    const filePasportDocDB = uploadPasportDocDBelemnt ? uploadPasportDocDBelemnt.split('-f-n-').pop() : '';
    
    const handlePickPasportDoc = () => {
        if (filePickerLicensePasportDoc.current) {
            filePickerLicensePasportDoc.current.click();
        }
    }

    const uploadHandleChnageRegistrationDoc = (e: any) => {
        let photos = e.target.files;
        var photoName: any[] = [];
        var errOneOfFile:string = '';
        if (photos.length > maxLenght) {
            setErrorFileSizeRegistration(true);
            if (filePickerLicenseRegistrationDoc.current) {
                filePickerLicenseRegistrationDoc.current.value = "";
            }
        } else {
            setErrorFileSizeRegistration(false);
            Array.from(photos).forEach((photo: any) => {
                if (photo.size > fileLimit) {
                    errOneOfFile = photo.name;
                } else {
                    photoName.push(photo.name);
                }
            });
            setErrorRegistrationDoc(errOneOfFile);
            setFilenameRegistrationDoc('Список загруженных документов')
        }

        setRegistrationAllFiles((prevFiles) => {
            const updatedFiles = new Map(prevFiles);
            for (let file of photos) {
              updatedFiles.set(file.name, file); // Use file name as the key to ensure uniqueness
              onChange(false, allFiles, allPasportFiles, updatedFiles);
            }
            return updatedFiles;
        });
    }

    const fileNameregistrationDocDB = uploadRegistrationDocDBelemnt ? uploadRegistrationDocDBelemnt.split('-f-n-').pop() : '';
    const handlePickRegistrationDoc = () => {
        if (filePickerLicenseRegistrationDoc.current) {
            filePickerLicenseRegistrationDoc.current.click();
        }
    }

    const handleRadioLegalChange = (score: any, inputName: string) => {
        setRadioLegalStatus(score);
        set_Form_Data({
            ...form_Data,
            [inputName]: score,
        });
        if (score === 'true') {
            let addrLiveRegion = form_Data.reprRegRegion;
            let addrLiveCity = form_Data.reprRegCity;
            let addrLiveStreet = form_Data.reprRegStreet;
            let addrLiveHouse = form_Data.reprRegHouse;
            let addrLiveCorpus = form_Data.reprRegCorpus;
            let addrLiveApp = form_Data.reprRegApp;
            let addrLivePostalCode = form_Data.reprPostalCode;
        
            set_Form_Data({
                ...form_Data,
                'addrLiveRegion': addrLiveRegion,
                'addrLiveCity': addrLiveCity,
                'addrLiveStreet': addrLiveStreet,
                'addrLiveHouse': addrLiveHouse,
                'addrLiveCorpus': addrLiveCorpus,
                'addrLiveApp': addrLiveApp,
                'addrLivePostalCode': addrLivePostalCode,
            });
        } else {
            let addrLiveRegion = form_Data.addrLiveRegion ? form_Data.addrLiveRegion : '';
            let addrLiveCity = form_Data.addrLiveCity ? form_Data.addrLiveCity : '';
            let addrLiveStreet = form_Data.addrLiveStreet ? form_Data.addrLiveStreet : '';
            let addrLiveHouse = form_Data.addrLiveHouse ? form_Data.addrLiveHouse : '';
            let addrLiveCorpus = form_Data.addrLiveCorpus ? form_Data.addrLiveCorpus : '';
            let addrLiveApp = form_Data.addrLiveApp ? form_Data.addrLiveApp : '';
            let addrLivePostalCode = form_Data.addrLivePostalCode ? form_Data.addrLivePostalCode : '' ;
            set_Form_Data({
                ...form_Data,
                'addrLiveRegion': addrLiveRegion,
                'addrLiveCity': addrLiveCity,
                'addrLiveStreet': addrLiveStreet,
                'addrLiveHouse': addrLiveHouse,
                'addrLiveCorpus': addrLiveCorpus,
                'addrLiveApp': addrLiveApp,
                'addrLivePostalCode': addrLivePostalCode,
            });
        }
    };

    const handleRadioPostChange = (score: any, inputName: string) => {
        setRadioPostStatus(score);
        set_Form_Data({
            ...form_Data,
            [inputName]: score,
        });

        if (score === 'true') {
            let postLiveRegion = form_Data.reprRegRegion;
            let postLiveCity = form_Data.reprRegCity;
            let postLiveStreet = form_Data.reprRegStreet;
            let postLiveHouse = form_Data.reprRegHouse;
            let postLiveCorpus = form_Data.reprRegCorpus;
            let postLiveApp = form_Data.reprRegApp;
            let postLivePostalCode = form_Data.reprPostalCode;
            set_Form_Data({
                ...form_Data,
                'postLiveRegion': postLiveRegion,
                'postLiveCity': postLiveCity,
                'postLiveStreet': postLiveStreet,
                'postLiveHouse': postLiveHouse,
                'postLiveCorpus': postLiveCorpus,
                'postLiveApp': postLiveApp,
                'postLivePostalCode': postLivePostalCode,
            });
        } else {
            let postLiveRegion = form_Data.postLiveRegion ? form_Data.postLiveRegion : '';
            let postLiveCity = form_Data.postLiveCity ? form_Data.postLiveCity : '';
            let postLiveStreet = form_Data.postLiveStreet ? form_Data.postLiveStreet  : '';
            let postLiveHouse = form_Data.postLiveHouse ? form_Data.postLiveHouse : '';
            let postLiveCorpus = form_Data.postLiveCorpus ? form_Data.postLiveCorpus : '';
            let postLiveApp = form_Data.postLiveApp ? form_Data.postLiveApp : '';
            let postLivePostalCode = form_Data.postLivePostalCode ? form_Data.postLivePostalCode : '';
            set_Form_Data({
                ...form_Data,
                'postLiveRegion': postLiveRegion,
                'postLiveCity': postLiveCity,
                'postLiveStreet': postLiveStreet,
                'postLiveHouse': postLiveHouse,
                'postLiveCorpus': postLiveCorpus,
                'postLiveApp': postLiveApp,
                'postLivePostalCode': postLivePostalCode,
            });
        }
    };

    return ( <>
        <CardText className="mt-5">Сведения о Единоличном исполнительном органе</CardText>
        <Card>
            <CardBody>
                <Form.Group as={Col} className="mb-3" controlId="validationOrganizationalStructureLegalEntity">
                    <FormLabel className='required'>Организационная структура юридического лица</FormLabel>
                    <Form.Control
                        type="text"
                        name="organizationalStructureLegalEntity"
                        value={form_Data.organizationalStructureLegalEntity}
                        onChange={chngFn}
                        className='w-100'
                        required
                        placeholder='Сведения о финансовом положении'
                    />
                    <Form.Control.Feedback type="invalid">
                        Введите корректные данные
                    </Form.Control.Feedback>
                </Form.Group>
                <Row>
                    <Col lg={6} className="body-form-list">
                        <div className="body-form-list-title">Для акционерного общества:</div>
                        <ul>
                            <li className="body-form-list-item">общее собрание акционеров акционерного общества;</li>
                            <li className="body-form-list-item">совет директоров (наблюдательный совет) акционерного общества;</li>
                            <li className="body-form-list-item">единоличный исполнительный орган акционерного общества (директор, генеральный директор);</li>
                            <li className="body-form-list-item">коллегиальный исполнительный орган акционерного общества (правление, дирекция);</li>
                        </ul>
                    </Col>
                    <Col lg={6} className="body-form-list">
                        <div className="body-form-list-title">Для Общества с ограниченной ответственностью:</div>
                        <ul>
                            <li className="body-form-list-item">общее собрание участников общества с ограниченной ответственностью;</li>
                            <li className="body-form-list-item">совет директоров (наблюдательный совет) общества с ограниченной ответственностью;</li>
                            <li className="body-form-list-item">единоличный исполнительный орган общества с ограниченной ответственностью (генеральный директор, президент и другие);</li>
                            <li className="body-form-list-item">коллегиальный исполнительный орган общества с ограниченной ответственностью (правление, дирекция и другие).</li>
                        </ul>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        <CardText className="mt-5">Персональный состав органов управления</CardText>
        <Card>
            <CardBody>
                <div className="body-form-list"><div className="body-form-list-title pb-4">Укажите персональный состав органов управления организации, за исключением сведений о персональном составе акционеров (участников) юридического лица, владеющих менее чем 5 % акции (долей) юридического лица</div></div>
                
                <Row>
                    <Form.Group as={Col}  lg={4} className="mb-3" controlId="validationownerSurname">
                        <FormLabel className='required'>Фамилия</FormLabel>
                        <Form.Control
                            type="text"
                            name="ownerSurname"
                            value={form_Data.ownerSurname}
                            onChange={chngFn}
                            
                            className='w-100'
                            placeholder='Иванов'
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}  lg={3} className="mb-3" controlId="validationownerName">
                        <FormLabel className='required'>Имя</FormLabel>
                        <Form.Control
                            type="text"
                            name="ownerName"
                            value={form_Data.ownerName}
                            onChange={chngFn}
                            
                            className='w-100'
                            placeholder='Иван'
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}  lg={3} className="mb-3" controlId="validationownerPart">
                        <FormLabel className='required'>Доля</FormLabel>
                        <Form.Control
                            type="number"
                            name="ownerPart"
                            value={form_Data.ownerPart}
                            min={1}
                            max={maxPart}
                            onChange={chngFn}
                            
                            className='w-100'
                            placeholder='Укажите % доли в организации'
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Col lg={2}>
                        <label className="w-100 form-label"></label>
                        <Button onClick={addStrings} className="btn-sm mt-4" variant="secondary">Добавить ещё </Button>
                    </Col>
                    <Form.Control
                        type="text"
                        name="ownerPartLine"
                        value={ownerPartLine}
                        readOnly
                        className="hidden"
                    />
                    <div className="listOfOwners">
                        {groups.map((group, index) => (
                        <li key={index} className="row mb-3">
                            <div className="col-4"><input className="w-100" type="text" value={group[0]} readOnly/></div>
                            <div className="col-3"><input className="w-100" type="text" value={group[1]} readOnly/></div>
                            <div className="col-3"><input className="w-100" type="text" value={group[2]} readOnly/></div>
                            <div className="col-2">
                                <Button onClick={() => removeGroup(index)} className="btn-sm mb-3" variant="secondary">Удалить</Button>
                            </div>
                        </li>
                        ))}
                    </div>
                    <Form.Group as={Col} className="mb-3">
                        <FormLabel>Наличие выгодоприобретателя</FormLabel>
                        <Form.Check
                        type="radio"
                        name="haveIncome"
                        value={haveIncomeStatus || 'false'}
                        checked={haveIncomeStatus === 'true' ? true : false}
                        onChange={e => handleCheckboxChange('true', 'haveIncome')}
                        label="Присутствует"
                        id="haveIncomeTrue"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="haveIncome"
                        value={haveIncomeStatus || 'false'}
                        checked={haveIncomeStatus === 'true' ? false : true}
                        onChange={e => handleCheckboxChange('false', 'haveIncome')}
                        label="Отсутствует"
                        id="haveIncomeFalse"
                        className="radio"
                    />
                    </Form.Group>
                </Row>
            </CardBody>
        </Card>
        <CardText className="mt-5">Сведения о бенефициарных владельцах</CardText>
        <Card>
            <CardBody>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Сведения о бенефициарных владельцах</FormLabel>
                    <Form.Check
                        type="radio"
                        name="beneficialOwner"
                        value="alone"
                        checked={(radioBeneficialOwnersStatus === 'alone' || radioBeneficialOwnersStatus === '') ? true : false}
                        onChange={e => handleRadionChange('alone', 'beneficialOwner')}
                        label="Единственным бенефициарным владельцем является единый исполнительный орган"
                        id="beneficialOwnerTrue"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="beneficialOwner"
                        value="notAlone"
                        checked={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                        onChange={e => handleRadionChange('notAlone', 'beneficialOwner')}
                        label="Есть другие бенефициарные владельцы"
                        id="beneficialOwnerFalse"
                        className="radio"
                    />
                </Form.Group>
                <div className={(radioBeneficialOwnersStatus === 'alone' || radioBeneficialOwnersStatus === '') ? 'd-none' : ''}>
                    <hr className="card-divider mt-4 mb-3"/>
                    <div className="card-form-title mb-4">Бенефициар 1</div>
                    <Form.Control
                        type="text"
                        name="benPartLine"
                        value={benPartLine}
                        readOnly
                        className="hidden"
                    />
                    <div className="listOfOwners">
                        {groupsBen.map((group, index) => (<>
                        <li key={index} className="row mb-3">
                            <div className="col-12"><b>Статус:</b> {group[0]} {group[1]}</div>
                            <div className="col-12"><b>ФИО:</b> {group[2]} {group[3]} {group[4]}</div>
                            <div className="col-12"><b>Дата рождения:</b> {group[5]}</div>
                            <div className="col-12"><b>Контакты:</b> {group[6]} {group[7]}</div>
                            <div className="col-12"><b>ИНН:</b> {group[8]}</div>
                            <div className="col-12"><b>Место рождения:</b> {group[9]}, {group[10]}</div>
                            <div className="col-12"><b>Паспорт:</b> {group[11]} {group[12]}, {group[13]}, {group[14] !== 'null' ? group[14]+',' : ''} {group[15]}</div>
                            <div className="col-12"><b>Адрес регистрации по паспорту:</b> {group[16]} {group[17]}, {group[18]} {group[19]}, {group[20]}</div>
                            <div className="col-12"><b>Адрес проживания:</b> {group[21] === 'true' ? 'Совпадает': 'Не совпадает'} с адресом регистрации по паспорту</div>
                            <div className="col-12"><b>Почтовый адрес:</b> {group[22] === 'true' ? 'Совпадает': 'Не совпадает'} с адресом регистрации по паспорту</div>
                            <div className="col-2 mt-3">
                                <Button onClick={() => removeBenGroup(index)} className="btn-sm mb-3" variant="secondary">Удалить</Button>
                            </div>
                        </li>
                        <div className="card-form-title mb-4">Бенефициар {index + 2}</div>
                        </>))}
                    </div>
                    <Form.Group as={Col} className="mb-3">
                        <FormLabel>Влияние на деятельность юридического лица</FormLabel>
                        <Form.Check
                            type="checkbox"
                            name="partControlOwner"
                            label="Владеет 25% акций и более"
                            id="partControlOwnerTrue"
                            className="radio"
                            onChange={e => handleCheckboxChange(!partControlOwnerChecked, 'partControlOwner')}
                            checked={partControlOwnerChecked}
                        />
                        <Form.Check
                            type="checkbox"
                            name="fullCOntrolOwner"
                            label="Может контролировать действия юридического лица"
                            id="partControlOwnerFalse"
                            className="radio"
                            onChange={e => handleCheckboxChange(!fullCOntrolOwnerChecked, 'fullCOntrolOwner')}
                            checked={fullCOntrolOwnerChecked}
                        />
                    </Form.Group>
                    <Row>
                        <Form.Group as={Col} lg={4} className="mb-3" controlId="validationbenStatus">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Статус</FormLabel>
                            <Form.Control
                                type="text"
                                name="benStatus"
                                value={form_Data.benStatus}
                                onChange={chngFn}
                                
                                className='w-100'
                                placeholder='Например, акционер'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col}  lg={4} className="mb-3" controlId="validationbenConfirmDoc">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Подтверждающий документ</FormLabel>
                            <Form.Control
                                type="text"
                                name="benConfirmDoc"
                                value={form_Data.benConfirmDoc}
                                onChange={chngFn}
                                className='w-100'
                                placeholder='Наименование документа, подтверждающего   статус бенефициара'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} lg={4} className="mb-3" controlId="validationLicenseBenConfirm">
                            <Form.Control 
                                type="file"
                                className="hidden"
                                onChange={uploadHandleChnageBenConfirm}
                                accept=".pdf, .jpg, .jpeg, .png, .heic"
                                required={(radioBeneficialOwnersStatus === 'alone' || radioBeneficialOwnersStatus === '' || fileBenConfirmDB !== '') ? false : true}
                                name="uploadBenConfirm"
                                ref={filePickerLicenseBenConfirm}
                                multiple
                            />
                            
                            <div className="filePicker d-flex align-items-center">
                                <div className="me-4 fileIcon" onClick={handlePickBenConfirm}><img src={selectFile} alt="Select file"/></div>
                                <div className="fileLable">{filenameBenConfirm}
                                    <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                </div>
                                {fileBenConfirmDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileBenConfirmDB} загружен</Alert> : ''}
                                {errorFileSizeConfirmDoc !== '' ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла {errorFileSizeConfirmDoc} больше лимита в {fileLimit / URL.FILE_LIMIT_PHOTO} Mb</Alert> : ''}
                                {errorFileSize2 ? <Alert className="d-flex w-100 mt-3" variant="danger">Превишено количество файлов</Alert> : ''}
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Файл обязателен к загрузке
                            </Form.Control.Feedback>

                            <ul className="mt-3">
                                {Array.from(allFiles.values()).map((file) => (
                                    <li key={file.name}>{file.name}</li>
                                ))}
                            </ul>
                        </Form.Group>
                    </Row>
                    <Row>
                    <CardText className="mt-5 card-divider">Основные данные</CardText>
                        <Form.Group lg={3} as={Col} className="mb-3" controlId="validationreprFio">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Фамилия</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprFio"
                                value={form_Data.reprFio}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Фамилия'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={3} as={Col} className="mb-3" controlId="validationrepName">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Имя</FormLabel>
                            <Form.Control
                                type="text"
                                name="repName"
                                value={form_Data.repName}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Имя'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={3} as={Col} className="mb-3" controlId="validationreprPatronimic">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Отчество</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprPatronimic"
                                value={form_Data.reprPatronimic}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Отчество'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={3} as={Col} className="mb-3" controlId="validatiodateAccessStart">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Дата рождения</FormLabel>
                            <Form.Control
                                type="date"
                                name="dateDateBirthday"
                                onChange={chngFn}
                                max={new Date().toISOString().slice(0, 10)}
                                defaultValue={form_Data.dateDateBirthday}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Дата рождения'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group lg={3} as={Col} className="mb-3" controlId="validationreprPhone">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Телефон</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprPhone"
                                value={form_Data.reprPhone}
                                onChange={chngFn}
                                pattern="^\+[0-9]{9,15}$"
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='+7'
                                isInvalid={ validated && !/^\+[0-9]{9,15}$/.test(form_Data.reprPhone)}
                            />
                            <Form.Control.Feedback type="invalid">
                            Телефон должен быть в формате +7
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={9} as={Col} className="mb-3" controlId="validationreprEmail">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Электронная почта</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprEmail"
                                value={form_Data.reprEmail}
                                onChange={chngFn}
                                pattern="^\S+@\S+\.\S+$"
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Электронная почта'
                                isInvalid={ validated && !/^\S+@\S+\.\S+$/.test(form_Data.reprEmail)}
                            />
                            <Form.Control.Feedback type="invalid">
                            Введите корректную почту
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <CardText className="mt-5 card-divider">Сведения и документы</CardText>
                        <Form.Group lg={12} as={Col} className="mb-3" controlId="validationreprInn">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>ИНН</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprInn"
                                value={form_Data.reprInn}
                                onChange={chngFn}
                               className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='ИНН'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={9} as={Col} className="mb-3" controlId="validationreprBirthdayPlace">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Место рождения</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprBirthdayPlace"
                                value={form_Data.reprBirthdayPlace}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Место рождения'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={3} as={Col} className="mb-3" controlId="validationreprNationality">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Гражданство</FormLabel>
                            <Form.Select
                                name="reprNationality"
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                defaultValue="RF"
                            >
                                <option value="RF">Российская Федерация</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group lg={3} as={Col} className="mb-3" controlId="validationreprPasport">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Документ, удостоверяющий личность</FormLabel>
                            <Form.Select
                                name="reprPasport"
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                defaultValue="Pasport"
                            >
                                <option value="Pasport">Паспорт</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={3} as={Col} className="mb-3" controlId="validationreprSerial">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Серия</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprSerial"
                                value={form_Data.reprSerial}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                placeholder='Серия'
                                isInvalid={ validated }
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={3} as={Col} className="mb-3" controlId="validationReprPasportNumber">
                            <FormLabel  className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Номер</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprPasportNumber"
                                value={form_Data.reprPasportNumber}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                placeholder='Номер'
                                isInvalid={ validated }
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={3} as={Col} className="mb-3" controlId="validatiodateAccessStart">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Дата выдачи</FormLabel>
                            <Form.Control
                                type="date"
                                name="pasportDate"
                                onChange={chngFn}
                                max={new Date().toISOString().slice(0, 10)}
                                defaultValue={form_Data.pasportDate}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Дата выдачи'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group lg={3} as={Col} className="mb-3" controlId="validationReprPasportNumber">
                            <FormLabel >Код подразделения</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprCodeDepartment"
                                value={form_Data.reprCodeDepartment}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                placeholder='Код подразделения'
                                isInvalid={ validated }
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={9} as={Col} className="mb-3" controlId="validationreprIssuedBy">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Кем выдан</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprIssuedBy"
                                value={form_Data.reprIssuedBy}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Кем выдан'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Form.Group as={Col} className="mb-3" controlId="validationLicensePasportDoc">
                        <Form.Control 
                            type="file"
                            className="hidden"
                            onChange={uploadHandleChnagePasportDoc}
                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                            required={(radioBeneficialOwnersStatus === 'alone' || radioBeneficialOwnersStatus === '' || filePasportDocDB !== '') ? false : true}
                            name="uploadPasportDocBen"
                            ref={filePickerLicensePasportDoc}
                            multiple
                        />
                        
                        <div className="filePicker d-flex align-items-center">
                            <div className="me-4 fileIcon" onClick={handlePickPasportDoc}><img src={selectFile} alt="Select file"/></div>
                            <div className="fileLable">{filenamePasportDoc}
                                <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                            </div>
                            
                            {filePasportDocDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {filePasportDocDB} загружен</Alert> : ''}
                            {errorPasportDoc !== '' ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла {errorPasportDoc} больше лимита в {fileLimit / URL.FILE_LIMIT_PHOTO} Mb</Alert> : ''}
                            {errorFileSizePasport ? <Alert className="d-flex w-100 mt-3" variant="danger">Превишено количество файлов</Alert> : ''}
                        </div>
                        <Form.Control.Feedback type="invalid">
                            Файл обязателен к загрузке
                        </Form.Control.Feedback>
                        <ul className="mt-3">
                            {Array.from(allPasportFiles.values()).map((file) => (
                                <li key={file.name}>{file.name}</li>
                            ))}
                        </ul>
                    </Form.Group>
                    <Row>
                        <CardText className="mt-5 card-divider">Адрес регистрации по паспорту</CardText>
                        <Form.Group lg={6} as={Col} className="mb-3" controlId="validationReprRegRegion">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Регион</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprRegRegion"
                                value={form_Data.reprRegRegion}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Регион'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={6} as={Col} className="mb-3" controlId="validationReprRegCity">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Населенный пункт</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprRegCity"
                                value={form_Data.reprRegCity}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Населенный пункт'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group lg={4} as={Col} className="mb-3" controlId="validationReprRegStreet">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Улица</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprRegStreet"
                                value={form_Data.reprRegStreet}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Улица'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationReprRegHouse">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Дом</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprRegHouse"
                                value={form_Data.reprRegHouse}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Дом'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationReprRegCorpus">
                            <FormLabel >Корпус</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprRegCorpus"
                                value={form_Data.reprRegCorpus}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                placeholder='Корпус'
                            />
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationReprRegApp">
                            <FormLabel>Квартира</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprRegApp"
                                value={form_Data.reprRegApp}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                placeholder='Квартира'
                            />
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationReprPostalCode">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Индекс</FormLabel>
                            <Form.Control
                                type="text"
                                name="reprPostalCode"
                                value={form_Data.reprPostalCode}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Индекс'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Form.Group as={Col} className="mb-3" controlId="validationLicenseRegistrationDoc">
                        <Form.Control 
                            type="file"
                            className="hidden"
                            onChange={uploadHandleChnageRegistrationDoc}
                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                            required={(radioBeneficialOwnersStatus === 'alone' || radioBeneficialOwnersStatus === '' || fileNameregistrationDocDB !== '') ? false : true}
                            name="uploadRegistrationDocBen"
                            ref={filePickerLicenseRegistrationDoc}
                        />
                        
                        <div className="filePicker d-flex align-items-center">
                            <div className="me-4 fileIcon" onClick={handlePickRegistrationDoc}><img src={selectFile} alt="Select file"/></div>
                            <div className="fileLable">{filenameRegistrationDoc}
                                <div className="small-txt">Формат файла должен соответствовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                            </div>
                            
                            {fileNameregistrationDocDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameregistrationDocDB} загружен</Alert> : ''}
                            {errorRegistrationDoc !== '' ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла {errorRegistrationDoc} больше лимита в {fileLimit / URL.FILE_LIMIT_PHOTO} Mb</Alert> : ''}
                            {errorFileSizeRegistration ? <Alert className="d-flex w-100 mt-3" variant="danger">Превишено количество файлов</Alert> : ''}
                        </div>
                        <Form.Control.Feedback type="invalid">
                            Файл обязателен к загрузке
                        </Form.Control.Feedback>

                        <ul className="mt-3">
                            {Array.from(allRegistrationFiles.values()).map((file) => (
                                <li key={file.name}>{file.name}</li>
                            ))}
                        </ul>
                    </Form.Group>
                    <CardText className="mt-5 card-divider">Адрес проживания</CardText>
                    <Form.Group as={Col} className="mb-3">
                        <FormLabel>Совпадает с адресом регистрации</FormLabel>
                        <Form.Check
                            type="radio"
                            name="isLiveAddSameAsReg"
                            value={radioLegalStatus || 'false'}
                            checked={radioLegalStatus === 'true' ? true : false}
                            onChange={e => handleRadioLegalChange('true', 'isLiveAddSameAsReg')}
                            label="Совпадает"
                            id="isLiveAddSameAsRegTrue"
                            className="radio"
                        />
                        <Form.Check
                            type="radio"
                            name="isLiveAddSameAsReg"
                            value={radioLegalStatus || 'false'}
                            checked={radioLegalStatus === 'true' ? false : true}
                            onChange={e => handleRadioLegalChange('false', 'isLiveAddSameAsReg')}
                            label="Не Совпадает"
                            id="isLiveAddSameAsRegFalse"
                            className="radio"
                        />
                    </Form.Group>
                    <Row className={radioLegalStatus === 'true' ? 'legalAddressContainer hidden' : 'legalAddressContainer'}>
                        <Form.Group lg={6} as={Col} className="mb-3" controlId="validationAddrLiveRegion">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Регион</FormLabel>
                            <Form.Control
                                type="text"
                                name="addrLiveRegion"
                                value={form_Data.addrLiveRegion}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Регион'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={6} as={Col} className="mb-3" controlId="validationAddrLiveCity">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Населенный пункт</FormLabel>
                            <Form.Control
                                type="text"
                                name="addrLiveCity"
                                value={form_Data.addrLiveCity}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Населенный пункт'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Row>
                            <Form.Group lg={4} as={Col} className="mb-3" controlId="validationAddrLiveStreet">
                                <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Улица</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="addrLiveStreet"
                                    value={form_Data.addrLiveStreet}
                                    onChange={chngFn}
                                    className='w-100 legal-form-1'
                                    required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                    placeholder='Улица'
                                />
                                <Form.Control.Feedback type="invalid">
                                    Введите корректные данные
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group lg={2} as={Col} className="mb-3" controlId="validationAddrLiveHouse">
                                <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Дом</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="addrLiveHouse"
                                    value={form_Data.addrLiveHouse}
                                    onChange={chngFn}
                                    className='w-100 legal-form-1'
                                    required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                    placeholder='Дом'
                                />
                                <Form.Control.Feedback type="invalid">
                                    Введите корректные данные
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group lg={2} as={Col} className="mb-3" controlId="validationAddrLiveCorpus">
                                <FormLabel >Корпус</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="addrLiveCorpus"
                                    value={form_Data.addrLiveCorpus}
                                    onChange={chngFn}
                                    className='w-100 legal-form-1'
                                    placeholder='Корпус'
                                />
                            </Form.Group>
                            <Form.Group lg={2} as={Col} className="mb-3" controlId="validationAddrLiveApp">
                                <FormLabel>Квартира</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="addrLiveApp"
                                    value={form_Data.addrLiveApp}
                                    onChange={chngFn}
                                    className='w-100 legal-form-1'
                                    placeholder='Квартира'
                                />
                            </Form.Group>
                            <Form.Group lg={2} as={Col} className="mb-3" controlId="validationLegalPostalCode">
                                <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Индекс</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="addrLivePostalCode"
                                    value={form_Data.addrLivePostalCode}
                                    onChange={chngFn}
                                    className='w-100 legal-form-1'
                                    required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                    placeholder='Индекс'
                                />
                                <Form.Control.Feedback type="invalid">
                                    Введите корректные данные
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Row>
                    <div className="formGroupInsideTitle my-4">Почтовый адрес</div>
                    <Form.Group as={Col} className="mb-3">
                        <FormLabel>Совпадает с адресом регистрации</FormLabel>
                        <Form.Check
                            type="radio"
                            name="isPostAddSameAsReg"
                            value={radioPostStatus || 'false'}
                            checked={radioPostStatus === 'true' ? true : false}
                            onChange={e => handleRadioPostChange('true', 'isPostAddSameAsReg')}
                            label="Совпадает"
                            id="isPostAddSameAsRegTrue"
                            className="radio"
                        />
                        
                        <Form.Check
                            type="radio"
                            name="isPostAddSameAsReg"
                            value={radioPostStatus || 'false'}
                            checked={radioPostStatus === 'true' ? false : true}
                            onChange={e => handleRadioPostChange('false', 'isPostAddSameAsReg')}
                            label="Не Совпадает"
                            id="isPostAddSameAsRegFalse"
                            className="radio"
                        />
                    </Form.Group>
                    <Row className={radioPostStatus === 'true' ? 'postAddressContainer hidden' : 'postAddressContainer'}>
                        <Form.Group lg={6} as={Col} className="mb-3" controlId="validationPostLiveRegion">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Регион</FormLabel>
                            <Form.Control
                                type="text"
                                name="postLiveRegion"
                                value={form_Data.postLiveRegion}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Регион'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={6} as={Col} className="mb-3" controlId="validationAddrLiveCity">
                            <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Населенный пункт</FormLabel>
                            <Form.Control
                                type="text"
                                name="postLiveCity"
                                value={form_Data.postLiveCity}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                placeholder='Населенный пункт'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Row>
                            <Form.Group lg={4} as={Col} className="mb-3" controlId="validationPostLiveStreet">
                                <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Улица</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="postLiveStreet"
                                    value={form_Data.postLiveStreet}
                                    onChange={chngFn}
                                    className='w-100 legal-form-1'
                                    required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                    placeholder='Улица'
                                />
                                <Form.Control.Feedback type="invalid">
                                    Введите корректные данные
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group lg={2} as={Col} className="mb-3" controlId="validationPostLiveHouse">
                                <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Дом</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="postLiveHouse"
                                    value={form_Data.postLiveHouse}
                                    onChange={chngFn}
                                    className='w-100 legal-form-1'
                                    required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                    placeholder='Дом'
                                />
                                <Form.Control.Feedback type="invalid">
                                    Введите корректные данные
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group lg={2} as={Col} className="mb-3" controlId="validationPostLiveCorpus">
                                <FormLabel >Корпус</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="postLiveCorpus"
                                    value={form_Data.postLiveCorpus}
                                    onChange={chngFn}
                                    className='w-100 legal-form-1'
                                    placeholder='Корпус'
                                />
                            </Form.Group>
                            <Form.Group lg={2} as={Col} className="mb-3" controlId="validationPostLiveApp">
                                <FormLabel>Квартира</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="postLiveApp"
                                    value={form_Data.postLiveApp}
                                    onChange={chngFn}
                                    className='w-100 legal-form-1'
                                    placeholder='Квартира'
                                />
                            </Form.Group>
                            <Form.Group lg={2} as={Col} className="mb-3" controlId="validationPostPostalCode">
                                <FormLabel className={radioBeneficialOwnersStatus === 'notAlone' ? 'required' : ''}>Индекс</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="postLivePostalCode"
                                    value={form_Data.postLivePostalCode}
                                    onChange={chngFn}
                                    className='w-100 legal-form-1'
                                    required={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Введите корректные данные
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <Button onClick={e => addNewBen(e)} className="btn-sm mt-4" variant="secondary">Добавить ещё одного бенефициара</Button>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>
    </> );
}
 
export default LeagalForm3;