import {FC, useContext, useState} from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../../index";
import CfaAccountSubMenu from '../account/parts/CfaAccountSubMenu';
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormLabel, Modal, Row } from 'react-bootstrap';

import TooltipInfo from '../tooltips/TooltipInfo';

import envelope from "../../img/icons/Envelope.svg";
import { useAllMyCFA } from '../queries/useAllMyCFA';
import getBlockedMoney from '../../helpers/getBlockedMoney';

const TransactionHistory:FC = () => {
    const {store} = useContext(Context);

    const ndflTax = 1000;
    const platformTax = 3;
    const minAmount = ndflTax + (ndflTax * platformTax * 0.01) + 1;

    const [show, setShow] = useState(false);
    const [showConfirmation, setshowConfirmation] = useState(false);
    const handleClose = () => setShow(false);
    const handleCloseConfirmation = () => setshowConfirmation(false);
    const handleShow = () => {
        setShow(true);
        set_Validated(false);
    }

    const [showWithdrawal, setShowWithdrawal] = useState(false);

    const handleCloseWithdrawal = () => setShowWithdrawal(false);
    const handleShowWithdrawal = () => {
        setShowWithdrawal(true);
        set_ValidatedTransaction(false);
        set_Form_Data_Transaction({
            ...form_Data_Transaction,
            sendAmount: '',
            totalAmount: 0
        });
    }
    const [validated, set_Validated] = useState(false);
    const [validatedTransaction, set_ValidatedTransaction] = useState(false);
    const submitForm = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            set_Validated(false);
        } else {
            set_Validated(true);
        }
    }

    const submitTransactionForm = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            const numberAccountFrom = form.elements.numberAccountFrom.value;
            const freeAmount = form.elements.freeAmount.value;
            const sendAmount = form.elements.sendAmount.value;
            const dateAdded = form.elements.dateAdded.value;
            const totalAmount = form.elements.totalAmount.value;

            store.saveTransactionInfo(store.user.email, store.user.id, numberAccountFrom, freeAmount, sendAmount, dateAdded, totalAmount);
            
            set_ValidatedTransaction(false);
            setshowConfirmation(true);
            setShowWithdrawal(false);
        } else {
            set_ValidatedTransaction(true);
        }
    }

    

    const [form_Data, set_Form_Data] = useState({
        accountNumber: '',
        bankBik: '',
        bankName: '',
        corpAccount: '',
        bankInn: '',
        accountOwner: '',
        paymentReason: ''
    });

    const [form_Data_Transaction, set_Form_Data_Transaction] = useState({
        numberAccountFrom: '',
        freeAmount: '',
        sendAmount: '',
        totalAmount: 0
    });

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    const chngFnTransaction = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        let totalAmountNew = parseInt(value) - ndflTax - (parseInt(value) * platformTax * 0.01);
        if (parseInt(value) < minAmount || value === '') {
            totalAmountNew = 0
        }
        set_Form_Data_Transaction({
            ...form_Data_Transaction,
            totalAmount: Math.round(totalAmountNew),
            [name]: value,
            
        });
    };
    const {data} = useAllMyCFA();
    let blockedMoney:number = 0;
    let blockedMoneyRedeem:number = 0;

    if (data && data?.myCfaInfo) {
        blockedMoney = getBlockedMoney(data.myCfaInfo, 'confirmation', 'buy');
        blockedMoneyRedeem = getBlockedMoney(store.releaseCfaInfo.releaseCfa, 'redeem_started');
    }

    return (<>
            {store.isAuth ? (<>
                <CfaAccountSubMenu class1="organization-link px-4" class2="organization-link px-4 active" class3="organization-link px-4"/>
                <Container className='mb-5'>
                    <h3 className='mt-5'>Номер лицевого счета</h3>
                    <p className='text-small mb-1'>Номер банковского счета <b>{store.bank.currentBankAccount}</b></p>
                    <div className='ammountOfAccount'><span>10 000 0000</span>  руб. <TooltipInfo/></div>
                    {blockedMoney !== 0 ? (<div className='blockedAmount'>Сумма заблокирована для покупки ЦФА: <span>{blockedMoney}</span>  руб.</div>) : <></>}
                    {blockedMoneyRedeem !== 0 ? (<div className='blockedAmount'>Сумма заблокирована для погашения ЦФА: <span>{blockedMoneyRedeem}</span>  руб.</div>) : <></>}
                    <Row className='cfa-add-amount-wrap mt-5'>
                        <Col lg={4}>
                            <div className='cfa-add-amount'>
                                <div className='cfa-add-amount-title text-small mb-2'>Свободно</div>
                                <div className='cfa-add-amount-summ'><span className='amount'>{blockedMoney}</span> <span className='text-small'>руб.</span></div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className='cfa-add-amount'>
                                <div className='cfa-add-amount-title text-small mb-2'>Зарезервировано</div>
                                <div className='cfa-add-amount-summ'><span className='amount'>{blockedMoneyRedeem}</span> <span className='text-small'>руб.</span></div>
                            </div>
                        </Col>
                        <Col className="text-end" lg={2}>
                            <Button className="w-100" variant="primary" onClick={handleShow}>Пополнить счет</Button>

                            <Modal className="cfa-modal" show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                                <Modal.Header closeButton></Modal.Header>
                                <Modal.Body>
                                    <h4>Пополнение лицевого счёта</h4>
                                    <p>Для пополнения лицевого счёта совершите банковский перевод по указанным реквизитам:</p>
                                    <Card className="mb-3 mt-5">
                                        <CardBody>
                                            <Form noValidate validated={validated} onSubmit={submitForm}>
                                                <Form.Group lg={12} as={Col} className="mb-3" controlId="validationaccountNumber">
                                                    <FormLabel className='fw-normal'>Номер счета</FormLabel>
                                                    <Form.Control
                                                        type="number"
                                                        name="accountNumber"
                                                        value={form_Data.accountNumber}
                                                        onChange={chngFn}
                                                        className='w-100 legal-form-1'
                                                        required
                                                        placeholder='ХХХХ ХХХХ ХХХХ ХХХХ'
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Введите корректные данные
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Row>
                                                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationbankBik">
                                                        <FormLabel className='fw-normal'>БИК банка получателя</FormLabel>
                                                        <Form.Control
                                                            type="number"
                                                            name="bankBik"
                                                            value={form_Data.bankBik}
                                                            onChange={chngFn}
                                                            className='w-100 legal-form-1'
                                                            required
                                                            placeholder='БИК банка'
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Введите корректные данные
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationbankBik">
                                                        <FormLabel className='fw-normal'>Банк получателя</FormLabel>
                                                        <Form.Control
                                                            type="text"
                                                            name="bankName"
                                                            value={form_Data.bankName}
                                                            onChange={chngFn}
                                                            className='w-100 legal-form-1'
                                                            required
                                                            placeholder='Наименование банка'
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Введите корректные данные
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationcorpAccount">
                                                        <FormLabel className='fw-normal'>Корр. счет</FormLabel>
                                                        <Form.Control
                                                            type="number"
                                                            name="corpAccount"
                                                            value={form_Data.corpAccount}
                                                            onChange={chngFn}
                                                            className='w-100 legal-form-1'
                                                            required
                                                            placeholder='Корреспондентский счет'
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Введите корректные данные
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationbankInn">
                                                        <FormLabel className='fw-normal'>ИНН</FormLabel>
                                                        <Form.Control
                                                            type="text"
                                                            name="bankInn"
                                                            value={form_Data.bankInn}
                                                            onChange={chngFn}
                                                            className='w-100 legal-form-1'
                                                            required
                                                            placeholder='ИНН Банка'
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Введите корректные данные
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <Form.Group lg={12} as={Col} className="mb-3" controlId="validationaccountOwner">
                                                    <FormLabel className='fw-normal'>Получатель</FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="accountOwner"
                                                        value={form_Data.accountOwner}
                                                        onChange={chngFn}
                                                        className='w-100 legal-form-1'
                                                        required
                                                        placeholder='Получатель'
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Введите корректные данные
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group lg={12} as={Col} className="mb-3" controlId="validationpaymentReason">
                                                    <FormLabel className='fw-normal'>Назначение платежа</FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="paymentReason"
                                                        value={form_Data.paymentReason}
                                                        onChange={chngFn}
                                                        className='w-100 legal-form-1'
                                                        required
                                                        placeholder='Пополнение собственного Лицевого счета №Х. НДС не облагается'
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Введите корректные данные
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Modal.Body>
                                <Modal.Footer>
                                    <p>Мы зачислим денежные средства в течение 1 рабочего дня <br/>
                                    Комиссия не взимается</p>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                        <Col className="text-end" lg={2}>
                            <Button className="w-100" variant="secondary" onClick={handleShowWithdrawal}>Вывод средств</Button>

                            <Modal className="cfa-modal" show={showWithdrawal} onHide={handleCloseWithdrawal} backdrop="static" keyboard={false} >
                                <Modal.Header closeButton></Modal.Header>
                                <Modal.Body>
                                    <h4>Поручение на вывод денежных средств</h4>
                                    <br/><br/>
                                    <Form noValidate validated={validatedTransaction} onSubmit={submitTransactionForm}>
                                        <Row>
                                            <Form.Group lg={6} as={Col} className="mb-3" controlId="validationbnumberAccountFrom">
                                                <FormLabel className='fw-normal'>Счет списания</FormLabel>
                                                <Form.Control
                                                    type="number"
                                                    name="numberAccountFrom"
                                                    value="4111111111111111"
                                                    className='w-100 legal-form-1'
                                                    disabled
                                                    placeholder='Номер лицевого счета'
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Введите корректные данные
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group lg={6} as={Col} className="mb-3" controlId="validationfreeAmount">
                                                <FormLabel className='fw-normal'>Свободных средств, ₽</FormLabel>
                                                <Form.Control
                                                    type="text"
                                                    name="freeAmount"
                                                    value="1 000 000"
                                                    className='w-100 legal-form-1'
                                                    disabled
                                                    placeholder='Сумма свободных д/с на счёте'
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Введите корректные данные
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Form.Group lg={12} as={Col} className="mb-3 sendAmount" controlId="validationfsendAmount">
                                            <FormLabel className='fw-normal'>Сумма списания</FormLabel>
                                            <Form.Control
                                                type="number"
                                                min={minAmount}
                                                name="sendAmount"
                                                value={form_Data_Transaction.sendAmount}
                                                onChange={chngFnTransaction}
                                                className='w-100 legal-form-1'
                                                required
                                                placeholder='Введите сумму'
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Введите корректные данные
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <p className="form-text text-small mb-3">Минимальная сумма: НДФЛ за текущий налоговый период + Комиссия <b>{minAmount} руб</b></p>
                                        <p className="form-text">Из суммы списания будет удержано:</p>
                                        <p className="form-text text-small">НДФЛ за текущий налоговый период: <span className='amountTax'>{ndflTax} руб.</span></p>
                                        <p className="form-text text-small">Комиссия за исполнение платёжного поручения: {platformTax} %</p>
                                        <br/><br/>
                                        <Form.Group lg={12} as={Col} className="mb-3 totalAmount" controlId="validationftotalAmount">
                                            <FormLabel className='fw-normal'>Итого к выводу</FormLabel>
                                            <Form.Control
                                                type="number"
                                                min={minAmount}
                                                name="totalAmount"
                                                value={form_Data_Transaction.totalAmount}
                                                className='w-100 legal-form-1'
                                                required
                                                disabled
                                                placeholder='XXXXXXX'
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Введите корректные данные
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Control
                                            type="text"
                                            name="dateAdded"
                                            value={new Date().toISOString().slice(0, 10).split('-').reverse().join('/')}
                                            className='hidden'
                                        />
                                        <div className='d-flex'>
                                            <Button className="mt-2 me-3" variant="primary" id="form-submit" type="submit">Отправить поручение</Button>
                                            <div className='btn-outline-wrap-gray mt-2 d-inline-flex'><Button variant="outline" id="form-submit" type="submit">Реквизиты зачисления денежных средств</Button></div>
                                        </div>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer></Modal.Footer>
                            </Modal>
                            <Modal show={showConfirmation} onHide={handleCloseConfirmation} backdrop="static" keyboard={false} >
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                <h4>Даные отправлены на обработку</h4>
                                <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleCloseConfirmation}>Закрыть</button>
                                </Modal.Body>
                                <Modal.Footer>
                                <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                                <div className='btn-outline-wrap-green mt-3 mb-4'>
                                <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Row>
                    <CardTitle className='mt-5'>История операций</CardTitle>
                    <Card className="mb-3">
                        <CardBody>
                           
                        </CardBody>
                    </Card>
                </Container>

            </>) : (<></>)}
        </>
    );
}
 
export default observer(TransactionHistory);