import {FC, useContext, useState} from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../../index";
import CfaAccountSubMenu from '../account/parts/CfaAccountSubMenu';
import { Button, Card, CardBody, CardTitle, Col, Container, Modal, Row } from 'react-bootstrap';
import emitImage from "../../img/icons/emiter-default.svg"
import {useParams } from 'react-router-dom';
import download from "../../img/icons/download.svg";
import BuyCFA from './modal/BuyCFA';

const CfaDetail:FC = () => {
    const {store} = useContext(Context);
    const params = useParams();
    let emitID:any = params.emitID
    let cfaID:any = params.cfaID;
    const cfaItem = store.cfaItem;
    if (cfaItem && cfaItem.ticker === undefined) {
        store.getDetailCFA(emitID, cfaID);
    }
    
    const [show, setShow] = useState (false);
    const handleClose = () => setShow(false);

    const buyCFA = () => {
        setShow(true);
        store.errorMessage = '';
    }

    return (<>
            {store.isAuth && store.user.isActivated ? (<>
                <CfaAccountSubMenu class1="organization-link px-4" class2="organization-link px-4" class3="organization-link px-4 active"/>
                <Container className='mb-5'>
                <div className='dashboard-cfa mt-5'>
                    <Row className="cfa-table-header mt-5 me-0 ms-0">
                        <Col lg={4}>Наименование</Col>
                        <Col lg={2}>Эмитент</Col>
                        <Col className="text-center" lg={1}>Количество</Col>
                        <Col className="text-center" lg={1}>Дата погашения</Col>
                        <Col className="text-center" lg={1}>Цена</Col>
                        <Col className="text-center" lg={2}>Стоимость</Col>
                        <Col className="text-end text-center" lg={2}></Col>
                        
                    </Row>
                    <Row className="align-items-center cfa-table-body mt-4 me-0 ms-0">
                        <Col className="ps-0" lg={4}>
                                <div className='cfa-table-title'>
                                    <div className='cfa-table-sub-title'>Digital-Grain_{cfaItem.ticker}</div>
                                    <div className='cfa-table-name'>{cfaItem.releaseName}</div>
                                </div>
                        </Col>
                        <Col lg={2} className='align-items-center'><img className="me-3" src={emitImage} alt="Эмитент"/><span>{cfaItem.emitName}</span></Col>
                        <Col className="text-center" lg={1}>{cfaItem.countCfa}</Col>
                        <Col className="text-center" lg={1}>{cfaItem.dateEmissionCfa}</Col>
                        <Col className="text-center" lg={1}>{cfaItem.priceCfa ? cfaItem.priceCfa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0} &#8381;</Col>
                        <Col className="text-center cmpleate-price-cfa" lg={2}>{cfaItem.countCfa ? (parseInt(cfaItem.countCfa) * parseInt(cfaItem.priceCfa)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0} &#8381;</Col>
                        <Col className="text-center" lg={1}>
                            <div className='btn-outline-wrap-gold mt-3 mb-4'>
                                <button onClick={() => buyCFA()} className='btn btn-outline'>Купить</button>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Modal className='cfa-modal' show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                    <BuyCFA emitID={emitID} cfaID={cfaID} show={show} />
                </Modal>
                <div className='detail-cfa-info mb-3'>
                    <CardTitle className='mt-5'>Детали выпуска</CardTitle>
                    <Button variant="secondary"><img src={download} alt="Скачать"/> Скачать эмиссионную документацию</Button>
                </div>
                <Card className="mb-3">
                    <CardBody>
                        <div className='d-flex justify-content-between cfa-table-detail'>
                            <div className='left d-flex gap-3'>
                                <div className='cfa-table-title gray-light'>
                                    <div className='cfa-table-name'>{ cfaItem.dateAdded ? new Date(cfaItem.dateAdded).toISOString().slice(0, 10).split('-').reverse().join('.') : ''}</div>
                                    <div className='cfa-table-sub-title'>дата выпуска ЦФА</div>
                                </div>
                                <div className='cfa-table-title gray-light'>
                                    <div className='cfa-table-name'>{cfaItem.dateStartShowCfa} <span className='ms-2'>00:00 ч</span></div>
                                    <div className='cfa-table-sub-title'>дата начала размещения ЦФА</div>
                                </div>
                                <div className='cfa-table-title gray-dark'>
                                    <div className='cfa-table-name'>{cfaItem.dateCompleateCfa} <span className='ms-2'>23:59 ч</span></div>
                                    <div className='cfa-table-sub-title'>дата окончания размещения ЦФА</div>
                                </div>
                            </div>
                            <div className='right d-flex gap-3'>
                                <div className='cfa-table-title success'>
                                    <div className='cfa-table-name'>{cfaItem.countCfaOrigin}</div>
                                    <div className='cfa-table-sub-title'>объем выпуска ЦФА</div>
                                </div>
                                <div className='cfa-table-title success'>
                                    <div className='cfa-table-name'>{cfaItem.priceCfa} &#8381;</div>
                                    <div className='cfa-table-sub-title'>цена ЦФА при эмиссии</div>
                                </div>
                                <div className='cfa-table-title success'>
                                    <div className='cfa-table-name'>{cfaItem.ownerType}</div>
                                    <div className='cfa-table-sub-title'>вид прав по ЦФА</div>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                </Container>
            </>) : (<></>)}
        </>
    );
}
 
export default observer(CfaDetail);